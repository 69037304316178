import { Characteristic } from "./Characteristic";
import { Conversation } from "./Conversation";
import { Dimension } from "./Dimension";
import { ExperimentInstance } from "./ExperimentInstance";
import { ExperimentLlmModelAverageResult } from "./ExperimentLlmModelAverageResult";
import { LlmModel } from "./LlmModel";
import { Persona } from "./Persona";
import { Template } from "./Template";
import { User } from "./User";
import { ExperimentCharacteristicResult } from "./ExperimentCharacteristicResult";
import { ExperimentPersonaResult } from "./ExperimentPersonaResult";

export enum ExperimentType {
  MULTIPLEPERSONASPERCONVERSATION = "MULTIPLEPERSONASPERCONVERSATION",
  DEFAULT = "DEFAULT",
  MULTIPLECHOICE = "MULTIPLECHOICE",
}
export enum ExperimentStatus {
  SETTING_UP = "SETTING_UP",
  RUNNING = "RUNNING",
  SUCCESSFUL = "SUCCESSFUL",
  UNSUCCESSFUL = "UNSUCCESSFUL",
}
export interface Experiment {
  id: string;
  title: string;
  description?: string;
  llmPrompt?: string;
  sampleText?: string;
  sampleScore?: number; // Decimal type can be replaced with a library like Decimal.js if needed
  scoreDenominator?: number;
  prototypeText?: string;
  useTemplate: boolean;
  useDemographicDescription: boolean;
  experimentType: ExperimentType;
  template?: Template;
  conversations?: Conversation[];
  personas?: Persona[];
  dimensions?: Dimension[];
  characteristics?: Characteristic[];
  experimentInstances?: ExperimentInstance[];
  characteristicResults?: ExperimentCharacteristicResult[];
  llmModelResults?: ExperimentLlmModelAverageResult[];
  personaResults?: ExperimentPersonaResult[];
  llmModels?: LlmModel[];
  iterations: number;
  ownerId: string;
  isPublic: boolean;
  status: ExperimentStatus;
  owner?: User;
  createdAt: Date;
  updatedAt: Date;
}
