import { Experiment } from "src/types/models/Experiment";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";
import { CardBody } from "react-bootstrap";
import { InputContainer } from "../forms/InputContainer";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useAuth } from "@clerk/clerk-react";
import { createDimension, fetchDimensions } from "src/api/dimensionAPI";
import { Dimension } from "src/types/models/Dimension";
import { Checkbox } from "../ui/checkbox";
import { useEffect, useState } from "react";
import { Label } from "../ui/label";
import { Combobox } from "../ui/combobox";
import { fetchExperiments } from "src/api/experimentAPI";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import routes from "src/routes";
import { Persona } from "src/types/models/Persona";
import { createPersona } from "src/api/personaAPI";
import exp from "constants";
import { CheckedState } from "@radix-ui/react-checkbox";
import { useToast } from "../hooks/use-toast";

type SetupPersonasForExperimentProps = {
  experiment: Experiment;
};

export const SetupPersonasForExperiment: React.FC<
  SetupPersonasForExperimentProps
> = ({ experiment }) => {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [selectedPersonas, setSelectedPersonas] = useState<Partial<Persona>[]>(
    [],
  );
  const queryClient = useQueryClient();

  const [experimentTemplate, setExperimentTemplate] =
    useState<Experiment | null>(null);
  const createPersonaMutation = useMutation({
    mutationFn: async (persona: Partial<Persona>) => {
      const freshToken = await getToken();
      if (!freshToken) {
        throw new Error(
          "Your message could not be sent because your account could not be authenticated.",
        );
      }
      return await createPersona(
        experiment.id,
        {
          ...persona,
        },
        freshToken,
      );
    },
    onSuccess: (data) => {
      toast({ title: "Successfully added personas to this experiment." });
      navigate(routes.experiment.show(experiment.id ?? ""));
    },
    onError: (error: Error) => {
      toast({
        title:
          "There was an unexpected error when adding personas to this experiment.",
        variant: "destructive",
      });

      console.error("Message creation error", error);
    },
  });
  const { data: experiments } = useQuery<Experiment[], Error>({
    queryKey: ["experiments"],
    queryFn: () =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error("Your authentication token could not be accessed");
        }
        return fetchExperiments(freshToken);
      })(),
  });
  const reformattedExperiments = experiments?.map((experiment) => ({
    value: experiment.id,
    label: experiment.title,
  }));
  const selectExperimentTemplate = (id: string | null | undefined) => {
    if (!id) {
      setExperimentTemplate(null);
    } else {
      const tempExperiment = experiments?.find(
        (experiment) => experiment.id === id,
      );
      if (!tempExperiment) {
        setExperimentTemplate(null);
        return;
      } else {
        setExperimentTemplate(tempExperiment);
      }
    }
  };
  const handleImportSelectedPersonas = async () => {
    try {
      const promises = selectedPersonas?.map((persona) =>
        createPersonaMutation.mutateAsync({
          ...persona,
          experimentId: experiment.id,
        }),
      );
      if (promises) {
        await Promise.all(promises);
      }
      queryClient.invalidateQueries({
        queryKey: ["experiment", experiment.id],
      });
      navigate(routes.experiment.llmsForExperiment(experiment.id ?? ""));
    } catch (error) {
      console.error("Error importing dimensions:", error);
    }
  };

  const characteristicsArray: string[] = [];
  experiment.dimensions?.forEach((dimension) =>
    dimension.characteristics?.forEach((characteristic) => {
      characteristicsArray.push(characteristic.name);
    }),
  );

  useEffect(() => {
    if (experimentTemplate && experimentTemplate.personas && experiment.id) {
      const personaList: Persona[] = [];
      for (const persona of experimentTemplate.personas) {
        let isDisabled = false;
        const reformattedCharacteristics: {
          name: string;
          experimentId: string;
        }[] = [];
        if (persona.characteristics) {
          for (const characteristic of persona.characteristics) {
            if (!characteristicsArray.includes(characteristic.name)) {
              isDisabled = true;
              break;
            }
            reformattedCharacteristics.push({
              name: characteristic.name,
              experimentId: experimentTemplate?.id ?? "",
            });
          }
        }
        if (isDisabled === false) {
          personaList.push({
            name: persona.name,
            characteristics: reformattedCharacteristics,
            description: persona.description,
            experimentId: experiment.id,
            id: persona.id,
          });
        }
      }
      setSelectedPersonas(personaList);
    }
  }, [experimentTemplate]);

  const handleClickPersona = (checked: CheckedState, persona: Persona) => {
    if (!checked) {
      return setSelectedPersonas((prev) =>
        prev.filter((dim) => dim.id !== persona.id),
      );
    } else {
      const newItem = {
        name: persona.name,
        description: persona.description,
        id: persona.id,
        characteristics: persona.characteristics?.map((characteristic) => ({
          name: characteristic.name,
          experimentId: experimentTemplate?.id ?? "",
        })),
      };
      return setSelectedPersonas((prev) => [...prev, newItem]);
    }
  };
  console.log("experiment", experiment);
  console.log("selected dimension ids", selectedPersonas);
  console.log("experimentTemplate", experimentTemplate);
  console.log("characteristic array", characteristicsArray);
  return (
    <Card>
      <CardHeader className="p-1 bg-henna-green-accent text-off-white rounded-t-sm">
        Personas for {experiment.title}
      </CardHeader>
      <CardContent className="flex flex-col gap-4">
        {experiment?.personas && experiment.personas.length > 0 && (
          <div>
            <h4>
              You have already added the following personas to your experiment
            </h4>
            {experiment.personas?.map((persona) => (
              <div key={persona.id} className="">
                <span>{persona.name}</span>
                <div className="flex gap-1 ml-4">
                  {persona.characteristics?.map((characteristic) => (
                    <div key={characteristic.id}>
                      <span>{characteristic.name},</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}

        <h5>Choose an existing experiment to import Personas</h5>
        <p className="helper-text text-sm font-bold">What are Personas?</p>
        <p className="helper-text text-sm">
          {`A persona is basically an imaginary person. The goal should be to select a persona for every combination of characteristics that you chose in the previous step. For example, if you are exploring race and gender dimensions, you might have a persona who is a black man and another persona who is white and non-binary, and so on.`}
        </p>
        <p className="helper-text text-sm font-bold">How do I add Personas?</p>
        <p className="helper-text text-sm">
          {`At the moment, you can only add dimensions that already exist in a public experiment, or an experiment that you own.`}
        </p>
        <p className="helper-text text-sm font-bold">
          Why are there some Personas listed that I cannot click?
        </p>
        <p className="helper-text text-sm">
          {`When you select an experiment below, you will see all of the Personas in that experiment. However, if you have not selected the dimensions and characteristics for that persona for THIS experiment, then you will not be able to add that persona to this experiment. `}
        </p>
        <Combobox
          items={
            reformattedExperiments?.filter(
              (item) => item.value !== experiment.id,
            ) ?? []
          }
          selectItem={selectExperimentTemplate}
        ></Combobox>
        <div>
          {experimentTemplate?.personas?.map((persona, dimensionIndex) => {
            let isDisabled = false;
            if (persona.characteristics) {
              for (const characteristic of persona.characteristics) {
                if (!characteristicsArray.includes(characteristic.name)) {
                  isDisabled = true;
                  break;
                }
              }
            }
            return (
              <div key={persona.id}>
                {" "}
                <Label
                  htmlFor={persona.id}
                  className={isDisabled ? "line-through" : ""}
                >
                  {persona?.name}
                </Label>
                <Checkbox
                  disabled={isDisabled}
                  id={persona.id}
                  checked={selectedPersonas?.some(
                    (item) => item.id === persona.id,
                  )}
                  onCheckedChange={(checked) =>
                    handleClickPersona(checked, persona)
                  }
                ></Checkbox>
                <div className="flex flex-row ml-4 gap-1">
                  {persona.characteristics?.map((characteristic) => (
                    <div key={characteristic.id} className="">
                      <span className={isDisabled ? "line-through" : ""}>
                        {" "}
                        {characteristic.name},
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
        <Button onClick={handleImportSelectedPersonas}>
          Import Selected Dimensions and Characteristics
        </Button>
      </CardContent>
    </Card>
  );
};
