import { useContext, useEffect, useState } from "react";
import { InputContainer } from "../forms/InputContainer";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createExperiment,
  deleteExperiment,
  fetchExperiments,
  updateExperiment,
} from "src/api/experimentAPI";
import { useAuth } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { Experiment } from "src/types/models/Experiment";
import { Combobox } from "../ui/combobox";
import routes from "src/routes";
import { Card, CardContent } from "../ui/card";
import { CardHeader } from "react-bootstrap";
import { useToast } from "../hooks/use-toast";
type CreateExperimentFormProps = {
  experiment?: Experiment;
};

export const CreateEditExperimentForm: React.FC<CreateExperimentFormProps> = ({
  experiment,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [prototypeText, setPrototypeText] = useState("");
  const [iterations, setIterations] = useState(10);
  const [llmPrompt, setLlmPrompt] = useState("");
  const [sampleText, setSampleText] = useState("");
  const [experimentTemplate, setExperimentTemplate] =
    useState<Experiment | null>(null);
  const [sampleScore, setSampleScore] = useState(887);
  const { getToken } = useAuth();
  const { toast } = useToast();

  const createExperimentMutation = useMutation({
    mutationFn: () =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error(
            "Your message could not be sent because your account could not be authenticated.",
          );
        }
        return await createExperiment(
          {
            title,
            description,
            prototypeText,
            iterations,
            llmPrompt,
            sampleText,
            sampleScore,
          },
          freshToken,
        );
      })(),
    onSuccess: (data) => {
      toast({ title: "Successfully created an experiment" });
      navigate(routes.experiment.editExperimentBasics(data.id));
    },
    onError: (error: Error) => {
      console.error("Message creation error", error);
      toast({
        title: "There was an unexpected error when creating this experiment",
        variant: "destructive",
      });
    },
  });
  const editExperimentMutation = useMutation({
    mutationFn: () =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error(
            "Your message could not be sent because your account could not be authenticated.",
          );
        }
        return await updateExperiment(
          experiment?.id ?? "",
          {
            title,
            description,
            prototypeText,
            iterations,
            llmPrompt,
            sampleText,
            sampleScore,
          },
          freshToken,
        );
      })(),
    onSuccess: (data) => {
      navigate(routes.experiment.show(data.id));
      toast({ title: "Successfully edited an experiment" });
    },
    onError: (error: Error) => {
      console.error("Message creation error", error);
      toast({
        title: "There was an unexpected error when updating this experiment",
        variant: "destructive",
      });
    },
  });
  const deleteExperimentMutation = useMutation({
    mutationFn: async (experimentId: string) => {
      console.log("beginning delete experiment mutation");
      const freshToken = await getToken();
      if (!freshToken) {
        throw new Error(
          "Your message could not be sent because your account could not be authenticated.",
        );
      }
      const result = await deleteExperiment(experimentId, freshToken);
      console.log("finished, result", result);
      return result;
    },
    onSuccess: (data) => {
      console.log("success");
      toast({
        title: "Successfully deleted an experiment",
      });
      navigate(routes.experiment.base);
    },
    onError: (error: Error) => {
      console.error("Experiment deletion  error", error);
      toast({
        title: "There was an unexpected error when deleting this experiment",
        variant: "destructive",
      });
    },
  });
  const { data: experiments } = useQuery<Experiment[], Error>({
    queryKey: ["experiments"],
    queryFn: () =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error("Your authentication token could not be accessed");
        }
        return fetchExperiments(freshToken);
      })(),
  });
  const reformattedExperiments = experiments?.map((experiment) => ({
    value: experiment.id,
    label: experiment.title,
  }));
  const handleCreateOrEditExperiment = (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    if (experiment) {
      editExperimentMutation.mutateAsync();
    } else {
      createExperimentMutation.mutateAsync();
    }
  };
  const selectExperimentTemplate = (id: string | null | undefined) => {
    if (!id) {
      setExperimentTemplate(null);
    } else {
      const tempExperiment = experiments?.find(
        (experiment) => experiment.id === id,
      );
      if (!tempExperiment) {
        setExperimentTemplate(null);
        return;
      } else {
        setExperimentTemplate(tempExperiment);
      }
    }
  };
  useEffect(() => {
    if (experimentTemplate) {
      setTitle(experimentTemplate.title);
      if (experimentTemplate.description) {
        setDescription(experimentTemplate.description);
      }
      if (experimentTemplate.prototypeText) {
        setPrototypeText(experimentTemplate.prototypeText);
      }
      if (experimentTemplate.iterations) {
        setIterations(experimentTemplate.iterations);
      }
      if (experimentTemplate.llmPrompt) {
        setLlmPrompt(experimentTemplate.llmPrompt);
      }
      if (experimentTemplate.sampleText) {
        setSampleText(experimentTemplate.sampleText);
      }
      if (experimentTemplate.sampleScore) {
        setSampleScore(experimentTemplate.sampleScore);
      }
    }
  }, [experimentTemplate]);
  useEffect(() => {
    if (experiment) {
      setTitle(experiment.title);
      if (experiment.description) {
        setDescription(experiment.description);
      }
      if (experiment.prototypeText) {
        setPrototypeText(experiment.prototypeText);
      }
      if (experiment.iterations) {
        setIterations(experiment.iterations);
      }
      if (experiment.llmPrompt) {
        setLlmPrompt(experiment.llmPrompt);
      }
      if (experiment.sampleText) {
        setSampleText(experiment.sampleText);
      }
      if (experiment.sampleScore) {
        setSampleScore(experiment.sampleScore);
      }
    }
  }, [experiment]);
  return (
    <div className="m-4">
      {!experiment && (
        <Card className=" m-12 max-w-96 bg-henna-green-accent">
          <CardHeader className="p-1 text-center text-off-white">
            Auto-fill fields using an existing experiment
          </CardHeader>
          <CardContent className="p-2 bg-off-white-grey flex flex-col gap-4">
            <span className="text-sm">
              Instead of entering each field manually, you can pick from an
              experiment that you have already created--or a publicly available
              experiment, to auto-fill the data below. Updating this field will
              replace any data you have filled out below.
            </span>
            <Combobox
              items={reformattedExperiments ?? []}
              selectItem={selectExperimentTemplate}
              placeholder="Select an Experiment"
              id="template"
            ></Combobox>
          </CardContent>
        </Card>
      )}

      <form
        className="flex flex-col gap-4 items-center w-full px-8"
        onSubmit={handleCreateOrEditExperiment}
      >
        <InputContainer>
          <Label htmlFor="title">Experiment Title</Label>
          <Input
            id="title"
            value={title}
            className=" col-span-2"
            onChange={(e) => setTitle(e.target.value)}
          ></Input>
        </InputContainer>
        <InputContainer>
          <Label htmlFor="description">Description</Label>
          <Textarea
            id="description"
            value={description}
            className="h-20 col-span-2"
            onChange={(e) => setDescription(e.target.value)}
          ></Textarea>
        </InputContainer>
        <InputContainer>
          <div className="flex flex-col">
            <Label htmlFor="llmPrompt">LLM Prompt</Label>
            <span className="helper-text">
              Here, you provide the assignment for the LLM. For example, grading
              a student essay.
            </span>
          </div>
          <Textarea
            id="llmPrompt"
            value={llmPrompt}
            className="h-60 col-span-2"
            onChange={(e) => setLlmPrompt(e.target.value)}
          ></Textarea>
        </InputContainer>
        <InputContainer>
          <div className="flex flex-col">
            <Label htmlFor="prototypeText">Submitted Text</Label>
            <span className="helper-text">
              This is the text that will be evaluated, for example, a student
              essay without any names or demographic information.
            </span>
          </div>

          <Textarea
            id="prototypeText"
            value={prototypeText}
            className="h-60 col-span-2"
            onChange={(e) => setPrototypeText(e.target.value)}
          ></Textarea>
        </InputContainer>
        <InputContainer>
          <div className="flex flex-col">
            <Label htmlFor="iterations">Number of iterations</Label>
            <span className="helper-text">
              This is the number of completely identical conversations that will
              be had with LLMs. Recommended range: 3-20.
            </span>
          </div>
          <Input
            id="iterations"
            type="number"
            className="col-span-2"
            value={iterations}
            onChange={(e) => setIterations(parseInt(e.target.value))}
          ></Input>
        </InputContainer>

        <InputContainer>
          <div className="flex flex-col">
            <Label htmlFor="sampleText">Sample Submitted Text</Label>
            <span className="helper-text">
              This is sample text that could be evaluated by an LLM, for example
              a student essay without any names or demographic information. This
              will be used to provide a sample to each LLM so they have a
              baseline for the task.
            </span>
          </div>
          <Textarea
            id="sampleText"
            value={sampleText}
            className="h-60 col-span-2"
            onChange={(e) => setSampleText(e.target.value)}
          ></Textarea>
        </InputContainer>
        <InputContainer>
          <div className="flex flex-col">
            <Label htmlFor="sampleScore">Sample Score (out of 1000)</Label>
            <span className="helper-text">
              This score will be paired with the Sample Submitted Text to train
              the LLMs so their grades will be more reliable.
            </span>
          </div>
          <Input
            id="sampleScore"
            type="number"
            max={1000}
            min={0}
            value={sampleScore}
            className=" col-span-2"
            onChange={(e) => setSampleScore(parseInt(e.target.value))}
          ></Input>
        </InputContainer>
        <Button>{experiment ? "Update" : "Create"}</Button>
      </form>
      {experiment && (
        <Button
          variant="destructive"
          onClick={() => deleteExperimentMutation.mutate(experiment.id)}
        >
          Delete Experiment
        </Button>
      )}
    </div>
  );
};
