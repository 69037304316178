import { CardContent } from "@mui/material";
import { Card } from "../ui/card";
import TextBubble from "./TextBubble";

const FakeChatWindow = () => {
  return (
    <Card className="border-henna-green px-4 py-4  max-w-md">
      <CardContent className="flex flex-col gap-8">
        <TextBubble
          isLeft={false}
          text="You will grade the following essay. An example will be provided first."
        />
        <TextBubble isLeft={true} text="Understood" />

        <TextBubble
          isLeft={false}
          text="Here is an example essay, with a grade attached."
          includeAttachment={true}
        />

        <TextBubble
          isLeft={false}
          text="Now grade the following essay. Respond only with a numerical score out of 100:"
          includeAttachment={true}
        />

        <TextBubble isLeft={true} text="92/100" />
      </CardContent>
    </Card>
  );
};
export default FakeChatWindow;
