import axios, { AxiosError } from "axios";
import { Experiment } from "../types/models/Experiment";

export interface ExperimentInfo {
  title: string;
  useDemograhpicDescription?: boolean;
  useTemplate?: boolean;
  experimentType?: string;
  prototypeText?: string;
  iterations?: number;
  description?: string;
  llmPrompt?: string;
  sampleText?: string;
  sampleScore?: number;
}
const backendURL = process.env.REACT_APP_BACKEND_URL;

export async function createExperiment(
  experimentInfo: ExperimentInfo,
  token: string | null,
): Promise<Experiment> {
  const response = await axios.post(
    `${backendURL}/experiment/`,
    { ...experimentInfo },
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.experiment) {
    return response?.data?.experiment;
  } else {
    throw new Error("did not return a experiment");
  }
}
export async function fetchExperiments(
  token: string | null,
): Promise<Experiment[]> {
  try {
    if (!token) {
      throw new Error("Auth token was not provided");
    }
    const response = await axios.get(`${backendURL}/experiment`, {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.data?.experiments) {
      return response?.data?.experiments;
    } else {
      throw new Error("did not return a experiment list");
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const message = error.response?.data.message;
      throw new Error(message);
    } else {
      throw new Error(error.message);
    }
  }
}
export async function fetchExperimentById(
  experimentId: string,
  token: string | null,
): Promise<Experiment> {
  try {
    // if (!token) {
    //   throw new Error("Auth token was not provided");
    // }
    const response = await axios.get(
      `${backendURL}/experiment/${experimentId}`,
      {
        headers: {
          "Content-Type": "application/json", // Example header
          Authorization: `Bearer ${token ?? ""}`,
        },
      },
    );
    if (response?.data?.experiment) {
      return response?.data?.experiment as Experiment;
    } else {
      throw new Error("did not return a experiment");
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const message = error.response?.data.message;
      throw new Error(message);
    } else {
      throw new Error(error.message);
    }
  }
}

export async function updateExperiment(
  experimentId: string,
  updateData: {
    title?: string;
    useTemplate?: boolean;
    experimentType?: string;
    prototypeText?: string;
    iterations?: number;
    description?: string;
    llmPrompt?: string;
    sampleText?: string;
    sampleScore?: number;
    characteristicIds?: string[];
    dimensionIds?: string[];
    llmModelIds?: string[];
  },
  token: string | null,
): Promise<Experiment> {
  if (!token) {
    throw new Error("Auth token was not provided");
  }
  const response = await axios.put(
    `${backendURL}/experiment/${experimentId}`,
    updateData,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.experiment) {
    return response.data.experiment;
  } else {
    throw new Error("Failed to update experiment");
  }
}
export async function runExperiment(
  experimentId: string,
  token: string | null,
): Promise<void> {
  if (!token) {
    throw new Error("Auth token was not provided");
  }
  const response = await axios.put(
    `${backendURL}/experiment/${experimentId}/run`,
    {},
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function deleteExperiment(
  experimentId: string,
  token: string,
): Promise<void> {
  if (!token) {
    throw new Error("Auth token was not provided");
  }
  console.log("beginning to delete an experiment.");
  const response = await axios.delete(
    `${backendURL}/experiment/${experimentId}`,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response.status !== 200) {
    throw new Error("Failed to delete experiment");
  }
  console.log("just deleted the experiment");
}
