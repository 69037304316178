import { CreateEditExperimentForm } from "src/components/experiments/CreateEditExperimentForm";
import { PageContainer } from "src/components/layout/PageContainer";
import SideBarLayout, {
  SidebarType,
} from "src/components/layout/SidebarLayout";

export default function CreateExperimentPage() {
  return (
    <PageContainer>
      <div>Create An Experiment</div>
      <CreateEditExperimentForm />
    </PageContainer>
  );
}
