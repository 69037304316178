import { Experiment } from "src/types/models/Experiment";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";
import { CardBody } from "react-bootstrap";
import { InputContainer } from "../forms/InputContainer";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useAuth } from "@clerk/clerk-react";
import { createDimension, fetchDimensions } from "src/api/dimensionAPI";
import { Dimension } from "src/types/models/Dimension";
import { Checkbox } from "../ui/checkbox";
import { useEffect, useState } from "react";
import { Label } from "../ui/label";
import { Combobox } from "../ui/combobox";
import { fetchExperiments } from "src/api/experimentAPI";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import routes from "src/routes";
import { CheckedState } from "@radix-ui/react-checkbox";
import { Characteristic } from "src/types/models/Characteristic";
import { useToast } from "../hooks/use-toast";

type ExperimentCardProps = {
  experiment: Partial<Experiment>;
};

export const SetupDimensionsAndCharacteristicsForExperiment: React.FC<
  ExperimentCardProps
> = ({ experiment }) => {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const [experimentTemplate, setExperimentTemplate] =
    useState<Experiment | null>(null);
  const [selectedDimensions, setSelectedDimensions] = useState<
    Partial<Dimension>[]
  >([]);
  const [selectedCharacteristicIds, setSelectedCharacteristicIds] = useState<
    string[]
  >([]);
  const createDimensionMutation = useMutation({
    mutationFn: async (dimension: Partial<Dimension>) => {
      const freshToken = await getToken();
      if (!freshToken) {
        throw new Error(
          "Your message could not be sent because your account could not be authenticated.",
        );
      }
      return await createDimension(
        {
          ...dimension,
        },
        freshToken,
      );
    },
    onSuccess: (data) => {
      toast({
        title:
          "You have successfully connected dimensions and characteristics to this experiment.",
      });
      navigate(routes.experiment.personasForExperiment(experiment.id ?? ""));
    },
    onError: (error: Error) => {
      console.error("Message creation error", error);
      toast({
        title:
          "There was an unexpected error when connecting these dimensions and characteristics to this experiment.",
        variant: "destructive",
      });
    },
  });
  const { data: experiments } = useQuery<Experiment[], Error>({
    queryKey: ["experiments"],
    queryFn: () =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error("Your authentication token could not be accessed");
        }
        return fetchExperiments(freshToken);
      })(),
  });
  const reformattedExperiments = experiments?.map((experiment) => ({
    value: experiment.id,
    label: experiment.title,
  }));

  useEffect(() => {
    if (experimentTemplate && experimentTemplate.dimensions) {
      const dimensionList: Partial<Dimension>[] = [];
      for (const dimension of experimentTemplate.dimensions) {
        const reformattedDimension: Partial<Dimension> = {
          name: dimension.name,
          description: dimension.description,
          id: dimension.id,
        };

        if (dimension.characteristics) {
          reformattedDimension.characteristics = [];
          for (const characteristic of dimension.characteristics) {
            reformattedDimension.characteristics.push({
              name: characteristic.name,
              id: characteristic.id,
              description: characteristic.description,
            });
          }
        }
        dimensionList.push(reformattedDimension);
      }
      setSelectedDimensions(dimensionList);
    }
  }, [experimentTemplate]);
  const selectExperimentTemplate = (id: string | null | undefined) => {
    if (!id) {
      setExperimentTemplate(null);
    } else {
      const tempExperiment = experiments?.find(
        (experiment) => experiment.id === id,
      );
      if (!tempExperiment) {
        setExperimentTemplate(null);
        return;
      } else {
        setExperimentTemplate(tempExperiment);
      }
    }
  };
  const handleImportAllDimensions = async () => {
    try {
      const promises = selectedDimensions.map((dimension) =>
        createDimensionMutation.mutateAsync({
          ...dimension,
          experimentId: experiment.id,
        }),
      );

      await Promise.all(promises);
      queryClient.invalidateQueries({
        queryKey: ["experiment", experiment.id],
      });
      navigate(routes.experiment.personasForExperiment(experiment.id ?? ""));
    } catch (error) {
      console.error("Error importing dimensions:", error);
    }
  };
  const handleCheckDimension = (
    checked: CheckedState,
    dimension: Dimension,
  ) => {
    if (!checked) {
      return setSelectedDimensions((prev) =>
        prev.filter((dim) => dim.id !== dimension.id),
      );
    } else {
      const newItem = {
        name: dimension.name,
        description: dimension.description,
        id: dimension.id,
      };
      return setSelectedDimensions((prev) => [...prev, newItem]);
    }
  };
  const handleCheckCharacteristic = (
    checked: CheckedState,
    dimension: Dimension,
    characteristic: Characteristic,
  ) => {
    setSelectedDimensions((prev) => {
      if (checked) {
        return prev.map((dim) =>
          dim.id === dimension.id
            ? {
                ...dim,
                characteristics: [
                  ...(dim.characteristics ?? []),
                  {
                    id: characteristic.id,
                    name: characteristic.name,
                    description: characteristic.description,
                  },
                ],
              }
            : dim,
        );
      } else {
        return prev.map((dim) =>
          dim.id === dimension.id
            ? {
                ...dim,
                characteristics: dim.characteristics?.filter(
                  (char) => char.id !== characteristic.id,
                ),
              }
            : dim,
        );
      }
    });
  };

  return (
    <Card className="rounded-xl">
      <CardHeader className="p-1 bg-henna-green-accent text-off-white rounded-t-sm">
        Dimensions and Characteristics for {experiment.title}
      </CardHeader>
      <CardContent className="p-2 flex flex-col gap-4">
        {experiment?.dimensions && experiment.dimensions.length > 0 && (
          <div>
            <h4>
              You have already added the following dimensions and
              characteristics to your experiment:
            </h4>
            {experiment.dimensions?.map((dimension) => (
              <div key={dimension.id} className="">
                <span>{dimension.name}</span>
                {dimension.characteristics?.map((characteristic) => (
                  <div key={characteristic.id} className="ml-4">
                    {" "}
                    <span>{characteristic.name}</span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
        <p className="helper-text text-sm font-bold">
          What are Dimensions and Characteristics?
        </p>
        <p className="helper-text text-sm">
          {`Let's say you want to assess whether an AI is biased towards people
          who live in a specific area. In this case, the dimension would be
          "place of origin", and the characteristics would be different
          possible places. You could choose the following characteristics: Portland, Seattle, and San Francisco, or you could compare inner city, suburban, rural, and outside of the US.`}
        </p>
        <p className="helper-text text-sm font-bold">
          How do I add Dimensions and Characteristics?
        </p>
        <p className="helper-text text-sm">
          {`At the moment, users can only select from characteristics and dimensions that exist in public experiments, or their own experiments. First, select an experiment below. Then, choose the dimensions and characteristics that you would like to add to this experiment.`}
        </p>
        <h5>Choose an existing experiment to import an existing dimension</h5>
        <Combobox
          items={
            reformattedExperiments?.filter(
              (item) => item.value !== experiment.id,
            ) ?? []
          }
          selectItem={selectExperimentTemplate}
        ></Combobox>
        <div>
          {experimentTemplate?.dimensions?.map((dimension, dimensionIndex) => (
            <div key={dimension.id}>
              <div className="flex flex-row items-center p-1 gap-1">
                <Label htmlFor={dimension.id}>
                  Dimension: {dimension?.name}
                </Label>
                <Checkbox
                  id={dimension.id}
                  checked={selectedDimensions.some(
                    (item) => item.id === dimension.id,
                  )}
                  onCheckedChange={(checked) =>
                    handleCheckDimension(checked, dimension)
                  }
                ></Checkbox>
              </div>

              {dimension.characteristics?.map((characteristic) => (
                <div
                  key={characteristic.id}
                  className="ml-4 flex flex-row items-center p-1 gap-1"
                >
                  <Label htmlFor={characteristic.id}>
                    {" "}
                    Characterisitc: {characteristic.name}
                  </Label>
                  <Checkbox
                    disabled={
                      !selectedDimensions.some((dim) => dim.id === dimension.id)
                    }
                    id={characteristic.id}
                    checked={selectedDimensions
                      ?.find((dim) => dim.id === dimension.id)
                      ?.characteristics?.some(
                        (item) => item.id === characteristic.id,
                      )}
                    onCheckedChange={(checked) =>
                      handleCheckCharacteristic(
                        checked,
                        dimension,
                        characteristic,
                      )
                    }
                  ></Checkbox>
                </div>
              ))}
            </div>
          ))}
        </div>
        <Button onClick={handleImportAllDimensions}>
          Import Selected Dimensions and Characteristics
        </Button>
      </CardContent>
    </Card>
  );
};
