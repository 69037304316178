import { Experiment } from "src/types/models/Experiment";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";
import { CardBody } from "react-bootstrap";
import { InputContainer } from "../forms/InputContainer";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useAuth } from "@clerk/clerk-react";
import { createDimension, fetchDimensions } from "src/api/dimensionAPI";
import { Dimension } from "src/types/models/Dimension";
import { Checkbox } from "../ui/checkbox";
import { useEffect, useState } from "react";
import { Label } from "../ui/label";
import { Combobox } from "../ui/combobox";
import {
  createExperiment,
  fetchExperiments,
  updateExperiment,
} from "src/api/experimentAPI";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import routes from "src/routes";
import { Persona } from "src/types/models/Persona";
import { createPersona } from "src/api/personaAPI";
import exp from "constants";
import { CheckedState } from "@radix-ui/react-checkbox";
import { LlmModel } from "src/types/models/LlmModel";
import { fetchLlmModels } from "src/api/llmModelAPI";
import { useToast } from "../hooks/use-toast";

type SetupLlmsForExperiment = {
  experiment: Experiment;
};

export const SetupLlmsForExperiment: React.FC<SetupLlmsForExperiment> = ({
  experiment,
}) => {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const [selectedModelIds, setSelectedModelIds] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const { data: llmModels } = useQuery<LlmModel[], Error>({
    queryKey: ["llmModels"],
    queryFn: () =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error("Your authentication token could not be accessed");
        }
        return fetchLlmModels(freshToken);
      })(),
  });
  const updateExperimentMutation = useMutation({
    mutationFn: async () => {
      const freshToken = await getToken();
      if (!freshToken) {
        throw new Error(
          "Your message could not be sent because your account could not be authenticated.",
        );
      }
      return await updateExperiment(
        experiment.id,
        { llmModelIds: selectedModelIds },
        freshToken,
      );
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["experiment", experiment.id],
      });
      toast({ title: "Successfully connected LLMs to this experiment" });
      navigate(routes.experiment.runExperiment(experiment.id));
    },
    onError: (error: Error) => {
      console.error("Message creation error", error);
      toast({
        title:
          "There was an unexpected error when connecting these LLMs to this experiment",
        variant: "destructive",
      });
    },
  });

  console.log("experiment", experiment);

  return (
    <Card>
      <CardHeader className="p-1 bg-henna-green-accent text-off-white rounded-t-sm">
        LLMs for {experiment.title}
      </CardHeader>
      <CardContent className="flex flex-col gap-4">
        {experiment?.llmModels && experiment.llmModels.length > 0 ? (
          <div>
            <h4>
              You have already added the following llms to your experiment
            </h4>
            {experiment.llmModels?.map((llm) => (
              <div key={llm.id} className="">
                <span>{llm.friendlyName}</span>
                <div className="flex gap-1 ml-4">
                  vendor: {llm.vendor?.name}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <span></span>
        )}
        <p className="helper-text text-sm font-bold">Instructions</p>
        <p className="helper-text text-sm">{`You must pick at least one LLM to run an experiment.`}</p>
        <div className="flex flex-col gap-1 w-full">
          <div className="grid grid-cols-3 gap-4">
            <span>API Name</span>
            <span>Input Cost per million tokens</span>
            <span>Output Cost per million tokens</span>
          </div>
          {llmModels
            ?.sort((a, b) => {
              const nameA = a.vendor?.name || "";
              const nameB = b.vendor?.name || "";
              return nameA.localeCompare(nameB);
            })
            ?.map((model, id) => {
              return (
                <div key={model.id} className="flex gap-4 w-full">
                  <Checkbox
                    id={model.id}
                    checked={selectedModelIds?.includes(model.id)}
                    onCheckedChange={(checked) =>
                      checked
                        ? setSelectedModelIds((prev) => [...prev, model.id])
                        : setSelectedModelIds((prev) =>
                            prev.filter((id) => id != model.id),
                          )
                    }
                  ></Checkbox>
                  <Label
                    htmlFor={model.id}
                    className="grid grid-cols-3 gap-4 w-full"
                  >
                    <span>{model?.friendlyName}</span>
                    <span>${model?.inputCostPerMillion}</span>
                    <span>${model?.outputCostPerMillion}</span>
                  </Label>
                  <div className="flex flex-row ml-4 gap-1"></div>
                </div>
              );
            })}
        </div>
        <Button onClick={() => updateExperimentMutation.mutate()}>
          Choose LLMs
        </Button>
      </CardContent>
    </Card>
  );
};
