import { Experiment } from "src/types/models/Experiment";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";
import { CardBody } from "react-bootstrap";
import { InputContainer } from "../forms/InputContainer";
import routes from "src/routes";
import { Link } from "react-router-dom";

type ExperimentCardProps = {
  experiment: Experiment;
  key?: string;
};

export const ExperimentCard: React.FC<ExperimentCardProps> = ({
  experiment,
  key,
}) => {
  console.log("experiment", experiment);

  return (
    <Link to={routes.experiment.show(experiment.id)} key={key}>
      <Card className="rounded-xl">
        <CardHeader className=" rounded-t-sm p-2 bg-henna-green-accent text-off-white">
          {experiment.title}
        </CardHeader>
        <CardContent>
          <InputContainer>
            <span>Description </span> <span>{experiment.description}</span>
          </InputContainer>
          <InputContainer>
            <span>Is Public: </span>{" "}
            <span>{experiment.isPublic ? "Yes" : "No"}</span>
          </InputContainer>
          <InputContainer>
            <span>Iterations: </span> <span>{experiment.iterations}</span>
          </InputContainer>
        </CardContent>
        <CardFooter>
          <InputContainer>
            <span>Experiment ID: </span> <span> {experiment.id}</span>
          </InputContainer>
        </CardFooter>
      </Card>
    </Link>
  );
};
