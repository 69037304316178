import axios, { AxiosError } from "axios";
import { Persona } from "../types/models/Persona";

const backendURL = process.env.REACT_APP_BACKEND_URL;

export async function createPersona(
  experimentId: string,
  personaInfo: Partial<Persona>,
  token: string | null,
): Promise<Persona> {
  const response = await axios.post(
    `${backendURL}/experiment/${experimentId}/persona/`,
    { ...personaInfo },
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.persona) {
    return response?.data?.persona;
  } else {
    throw new Error("did not return a persona");
  }
}
// export async function fetchPersonas(
//   experimentId: string,
//   token: string | null,
// ): Promise<Persona[]> {
//   try {
//     if (!token) {
//       throw new Error("Auth token was not provided");
//     }
//     const response = await axios.get(
//       `${backendURL}/experiment/${experimentId}/persona`,
//       {
//         headers: {
//           "Content-Type": "application/json", // Example header
//           Authorization: `Bearer ${token}`,
//         },
//       },
//     );
//     if (response?.data?.personas) {
//       return response?.data?.personas;
//     } else {
//       throw new Error("did not return a persona list");
//     }
//   } catch (error: any) {
//     if (error instanceof AxiosError) {
//       const message = error.response?.data.message;
//       throw new Error(message);
//     } else {
//       throw new Error(error.message);
//     }
//   }
// }
export async function fetchPersonaById(
  experimentId: string,

  personaId: string,
  token: string | null,
): Promise<Persona> {
  try {
    // if (!token) {
    //   throw new Error("Auth token was not provided");
    // }
    const response = await axios.get(
      `${backendURL}/experiment/${experimentId}/persona/${personaId}`,
      {
        headers: {
          "Content-Type": "application/json", // Example header
          Authorization: `Bearer ${token ?? ""}`,
        },
      },
    );
    if (response?.data?.persona) {
      return response?.data?.persona as Persona;
    } else {
      throw new Error("did not return a persona");
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const message = error.response?.data.message;
      throw new Error(message);
    } else {
      throw new Error(error.message);
    }
  }
}

export async function updatePersona(
  experimentId: string,

  personaId: string,
  updateData: {
    title?: string;
    useTemplate: boolean;
    personaType: string;
    prototypeText: string;
    iterations?: number;
    description?: string;
    llmPrompt?: string;
    sampleText?: number;
    sampleScore?: number;
    characteristicIds?: string[];
    personaIds?: string[];
  },
  token: string | null,
): Promise<Persona> {
  if (!token) {
    throw new Error("Auth token was not provided");
  }
  const response = await axios.put(
    `${backendURL}/experiment/${experimentId}/persona/${personaId}`,
    updateData,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.persona) {
    return response.data.persona;
  } else {
    throw new Error("Failed to update persona");
  }
}

export async function deletePersona(
  experimentId: string,

  personaId: string,
  token: string,
): Promise<void> {
  if (!token) {
    throw new Error("Auth token was not provided");
  }
  const response = await axios.delete(
    `${backendURL}/experiment/${experimentId}/persona/${personaId}`,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response.status !== 200) {
    throw new Error("Failed to delete persona");
  }
}
