import { Outlet } from "react-router-dom";
import { Toaster } from "src/components/ui/toaster";

export default function RootLayout() {
  return (
    <div>
      <Outlet></Outlet>
      <Toaster />
    </div>
  );
}
