import React, { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/layout/ChatLayout";
import HomePageLayout from "../components/layout/HomePageLayout";
import TaskCard from "../components/TaskCard";
import PressCard from "../components/PressCard";
const HomePage: React.FC = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  type ThemeMode = "light" | "dark" | "auto";

  return (
    <HomePageLayout pageName="Home">
      <div className="flex flex-col gap-16 bg-off-white">
        <section
          className="bg-henna-background page-layout-horizontal-padding pb-10 pt-4"
          id="home"
        >
          <div className="grid md:grid-cols-2 justify-between items-end md:items-center">
            <div className="flex flex-col gap-6 md:max-w-[500px] items-start  py-2">
              <h1 className="text-5xl font-bold mt-2">
                The safe way to quickly adopt AI.
              </h1>
              <h3 className="text-2xl grow-0">
                {" "}
                Run tailored audits for fairness, inclusivity, and safety
              </h3>
              <div className="flex items-start">
                {/* <Link to="/leaderboard" className="btn-henna mb-4">
                <span>View Our Leaderboard</span>
              </Link> */}
                <Link
                  to="https://calendly.com/arshhaque/call"
                  className="btn-henna"
                >
                  <span>Get Started Free</span>
                </Link>
              </div>
            </div>
            <img
              src="./talking-people-with-diagonals.png"
              className="rounded-xl object-contain xl:max-h-[600px]"
            />
          </div>
        </section>
        <section className="page-layout-horizontal-padding pt-1">
          <div className="flex flex-col justify-between items-center gap-2 ">
            <h2 className="text-4xl md:text-5xl">Supported By</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 md:gap-4 justify-center justify-items-center items-center ">
              <img src="/rain-catalysts-logo.png" className="max-h-32" />
              <img src="/venture-crush-cropped.png" className=" max-h-16" />
              <img src="/4.0-schools.png" className="max-h-32" />
              <img src="/osu-primarylogo.png" className="max-h-16" />
              <img
                src="/Portland-State-University-logo-cropped.png"
                className="max-h-32"
              />
            </div>
          </div>
        </section>
        <section
          className=" page-layout-horizontal-padding flex flex-col gap-10 pt-1"
          id="problem"
        >
          <h3 className="md:text-center text-4xl md:text-5xl font-bold">
            Unchecked AI use is a big risk for lawsuits & fines
          </h3>
          <div className="flex flex-row justify-center">
            <img src="/divider.png" />
          </div>

          <div className="grid md:grid-cols-2 gap-28">
            <div>
              <img src="./talking-people-with-ovals.png"></img>
            </div>
            {/* this will be a news card */}
            <div className="flex flex-col gap-5 h -full items-start justify-center">
              <h3 className="text-3xl md:text-4xl mb-4 font-bold">Lawsuits</h3>
              <img src="./divider-start.png" alt="divider" />
              <h4 className="header-henna-6 font-bold">
                iTutorGroup to pay $365,000 to settle EEOC Discriminatory Hiring
                Suit
              </h4>
              <p>
                Settles Federal Charges Tutoring Provider Programmed its Online
                Software to Automatically Reject More Than 200 Older Applicants
              </p>
              <p>
                NEW YORK – iTutorGroup, three integrated companies providing
                English-language tutoring services to students in China, will
                pay $365,000 and furnish other relief to settle an employment
                discrimination lawsuit filed by the U.S. Equal Employment
                Opportunity Commission (EEOC), the federal agency announced
                today.
              </p>
              <Link
                to="https://www.eeoc.gov/newsroom/itutorgroup-pay-365000-settle-eeoc-discriminatory-hiring-suit"
                className="btn-henna"
              >
                <span>Read More</span>
              </Link>
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-28">
            {/* this will be a news card */}
            <div className="flex flex-col gap-5 h-full items-start justify-center order-last md:order-first">
              <h3 className="text-3xl md:text-4xl mb-4 font-bold">
                Regulations
              </h3>
              <img src="./divider-start.png" alt="divider" />
              <h4 className="header-henna-6 font-bold">
                Colorado Governor Signs Comprehensive AI Bill
              </h4>
              <p>
                On May 17, 2024, Colorado became the first state to enact
                comprehensive AI legislation. Governor Jared Polis signed Senate
                Bill 24-205, “Concerning Consumer Protections in Interactions
                with Artificial Intelligence Systems,” (“the Colorado AI Act”),
                which introduces novel requirements for developers and deployers
                of high-risk artificial intelligence (AI) systems.
              </p>
              <p>
                Notably, on the same day he signed the Colorado AI Act into law,
                Polis wrote to the Colorado General Assembly that he had
                “reservations” about the law,
              </p>
              <Link
                to="https://blog.dciconsult.com/colorado-ai-bias-audit-law"
                className="btn-henna"
              >
                <span>Read More</span>
              </Link>
            </div>
            <div className="">
              <img src="./people-talking-at-standing-desk.png"></img>
            </div>
          </div>
        </section>
        <section
          className="page-layout-horizontal-padding flex flex-col gap-10 pt-1"
          id="solution"
        >
          <div className="flex flex-col gap-3">
            <h3 className="md:text-center text-5xl md:text-6xl font-bold">
              We run an audit checking your AI tools for bias{" "}
            </h3>
            <p className="text-center">Get results in just 24 hours</p>
            <div className="flex flex-row justify-center">
              <img src="/divider.png" />
            </div>
          </div>

          <div className="grid lg:grid-cols-3 gap-6 justify-center pb-5">
            <TaskCard
              header="Choose your tools"
              description="Such as ChatGPT, Grok, or Claude"
              bodyHeader="Tools"
              firstItemText="ChatGPT"
              secondItemText="Grok"
              thirdItemText="Claude"
              firstItemUrl="/chatGPT-logo.png"
              secondItemUrl="/grok-logo.png"
              thirdItemUrl="/claude-logo.png"
              step="01"
            />
            <TaskCard
              header="Choose a task"
              description="Such as reviewing a resume or grading an assignment"
              bodyHeader="Tasks"
              firstItemText="Grading"
              secondItemText="Admissions"
              thirdItemText="Hiring"
              firstItemUrl="/grading-icon.png"
              secondItemUrl="/admissions-icon.png"
              thirdItemUrl="/hiring-icon.png"
              step="02"
            />
            <TaskCard
              header="Choose some Personas"
              description="Based on areas such as race, gender, or geography"
              bodyHeader="Personas"
              firstItemText=""
              secondItemText=""
              thirdItemText=""
              firstItemUrl="/persona-1.png"
              secondItemUrl="/persona-2.png"
              thirdItemUrl="/persona-3.png"
              step="03"
            />
          </div>
          <div className="flex flex-col items-center justify-center gap-5">
            <span className="block">
              We then run 1,000+ simulations with the AI tool and deliver a
              report on bias.
            </span>
            <Link
              to="https://calendly.com/arshhaque/call"
              className="btn-henna"
            >
              <span>Get Started</span>
            </Link>
          </div>
        </section>
        <section
          className="page-layout-horizontal-padding flex flex-col gap-10 pt-1"
          id="press"
        >
          <div className="flex flex-col gap-3">
            <h3 className="text-center text-4xl md:text-5xl">Press Coverage</h3>
            <div className="flex flex-row justify-center">
              <img src="/divider.png" />
            </div>
          </div>

          <div className="grid lg:grid-cols-3 gap-4 md:gap-12 justify-center mb-5 pb-5">
            <PressCard
              header="PSU Accelerator Leader harnesses AI for a DEI Chatbot"
              body="Arsh Haque, PSU Business Accelerator Director, founded Henna, an AI-powered DEI platform using a chatbot to guide teams on inclusivity. They're seeking funding to expand the platform."
              tags={["PBJ", "App Launch"]}
              imageLink="/pbj-with-background.png"
              author="Malia Spencer"
              date="Apr 22, 2024"
              externalLink="https://www.bizjournals.com/portland/c/40-under-40/25484/arsh-haque-of-psu-business-accelerator.html"
            />
            <PressCard
              header="Henna Secures $30,000 from PSU’s University Venture..."
              body="Henna secures $30,000 from PSU’s University Venture Development Fund to enhance AI fairness and safety. The funding supports the launch of a no-code AI auditing platform and a public leaderboard for bias evaluation."
              tags={["Science Mag", "Funding"]}
              imageLink="/science-with-background.png"
              author="Travis Woodland"
              date="Aug 28, 2024"
              externalLink="https://scienmag.com/henna-secures-30000-from-psus-university-venture-development-fund-to-enhance-ai-fairness-safety/"
            />
            <PressCard
              header="Meet the 2024 Tiny Fellows"
              body="Introducing the 2024 Tiny Fellows! This year’s cohort of 128 education entrepreneurs will spend the next year turning their innovative ideas into reality, supported by a $5,500 grant, expert coaching, and a peer community."
              tags={["Medium", "Fellowship"]}
              imageLink="/4.0-schools-v2.png"
              author="4.0 Schools"
              date="Feb 8, 2024"
              externalLink="https://scienmag.com/henna-secures-30000-from-psus-university-venture-development-fund-to-enhance-ai-fairness-safety/"
            />
          </div>
        </section>
        {/* <section
          className="page-layout-horizontal-padding flex flex-col gap-10"
          id=""
        >
          <div
            className="grid grid-cols-2 gap-3 bg-henna-dark-green-2 rounded p-10"
            id="dark-green-background-landing-page-section"
          >
            <div className="flex flex-col justify-center gap-5">
              <h3 className=" header-henna-3 text-white">
                You’re just 24 hours away from results.
              </h3>
              <span className="text-white">
                Get your tailored AI audit today.
              </span>
              <div>
                <Link
                  to="https://calendly.com/arshhaque/call"
                  className="btn-henna-white"
                >
                  Get Started Free
                </Link>
              </div>
            </div>
            <div className="p-10" id="cirlce-background-section">
              <img src="/rocketship.png" className="h-3/4"></img>
              <span>test</span>
            </div>
          </div>
        </section> */}
        {/* <section className="bg-henna-background pt-2 mb-10 page-layout-horizontal-padding">
        <div className="flex flex-col md:flex-row justify-between items-end ml-3">
          <div className="flex flex-col gap-6 ml-3 grow-0 md:max-w-[500px] ">
            <h1 className="text-4xl font-bold">
              {`Also check out the Henna chatbot—it's an `}
              <span className="text-henna-yellow">AI Assistant</span>{" "}
              <span>that helps teams with</span>{" "}
              <span className="text-henna-yellow">
                {`Diversity, Equity, & Inclusion`}
              </span>
              .
            </h1>
            <h3 className="text-2xl grow-0">
              {" "}
              The Henna chatbot helps teams conduct listening tours, craft team
              values, and align their actions to those values.
            </h3>
            <div className="flex items-center m-3">
              <Link
                to="https://drive.google.com/file/d/1wHJYO_2WLzggWWb3q5j1HGij7aGc87VF/view"
                className="btn-henna"
              >
                <span>Learn More</span>
              </Link>
            </div>
          </div>

          <img
            src="./henna-image-right-oriented.png"
            className="rounded-xl object-contain"
            style={{ height: "450px" }}
          />
        </div>
      </section> */}
      </div>
    </HomePageLayout>
  );
};

export default HomePage;
