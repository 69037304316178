import { Experiment, ExperimentStatus } from "src/types/models/Experiment";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";
import { CardBody } from "react-bootstrap";
import { InputContainer } from "../forms/InputContainer";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useAuth } from "@clerk/clerk-react";
import { createDimension, fetchDimensions } from "src/api/dimensionAPI";
import { Dimension } from "src/types/models/Dimension";
import { Checkbox } from "../ui/checkbox";
import { useEffect, useState } from "react";
import { Label } from "../ui/label";
import { Combobox } from "../ui/combobox";
import {
  createExperiment,
  fetchExperiments,
  runExperiment,
  updateExperiment,
} from "src/api/experimentAPI";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import routes from "src/routes";
import { Persona } from "src/types/models/Persona";
import { createPersona } from "src/api/personaAPI";
import exp from "constants";
import { CheckedState } from "@radix-ui/react-checkbox";
import { LlmModel } from "src/types/models/LlmModel";
import { fetchLlmModels } from "src/api/llmModelAPI";
import { DataTable } from "../howItWorks/howItWorksDataTable/data-table";
import HowItWorksTable from "../howItWorks/HowItWorksTable";
import HowItWorksIntersectionalityTable from "../howItWorks/HowItWorksIntersectionalityTable";
import { HowItWorksChart } from "../howItWorks/HowItWorksChart";

type ExperimentResultsProps = {
  experiment: Experiment;
};

export const ExperimentResults: React.FC<ExperimentResultsProps> = ({
  experiment,
}) => {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const [selectedModelIds, setSelectedModelIds] = useState<string[]>([]);
  const queryClient = useQueryClient();

  console.log("experiment", experiment);

  return (
    <div>
      {experiment.status === ExperimentStatus.SUCCESSFUL && (
        <>
          <HowItWorksChart experiment={experiment} />
          <HowItWorksTable data={experiment} />
          <span>Intersectionality Table</span>
          <HowItWorksIntersectionalityTable data={experiment} />
        </>
      )}
      {experiment.status === ExperimentStatus.SETTING_UP && (
        <span className="header-henna-6">
          You cannot view results because you have not yet run the experiment.
        </span>
      )}
      {experiment.status === ExperimentStatus.UNSUCCESSFUL && (
        <span className="header-henna-6">
          You cannot view results because there was an error when running this
          experiment.
        </span>
      )}
      {experiment.status === ExperimentStatus.RUNNING && (
        <span className="header-henna-6">
          You cannot view results because this experiment is currently running.
        </span>
      )}
    </div>
  );
};
