import FullPageSection from "../layout/FullPageSection";
import EnterPasswordForm from "../user/EnterPasswordForm";

const HowItWorksLogin = (props: {
  setKnowsPassword: (val: boolean) => void;
}) => {
  return (
    <FullPageSection className="">
      <EnterPasswordForm setKnowsPassword={props.setKnowsPassword} />
    </FullPageSection>
  );
};
export default HowItWorksLogin;
