const NumberedItem = (props: {
  linkUrl: string;
  linkText: string;
  description: string;
}) => {
  const { linkUrl, description, linkText } = props;
  return (
    <li className="flex flex-col w-40">
      <a href={linkUrl} className="text-xl font-bold text-henna-green">
        {linkText}
      </a>
      <span className="italic">{description}</span>
    </li>
  );
};
export default NumberedItem;
