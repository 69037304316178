import * as React from "react";

import { cn } from "src/lib/utils";

const FullPageSection = React.forwardRef<
  HTMLElement,
  React.HTMLAttributes<HTMLElement>
>(({ className, ...props }, ref) => (
  <section
    className={cn(
      "bg-henna-background page-layout-horizontal-padding pb-10 pt-16 g",
      className,
    )}
    ref={ref}
    {...props}
  ></section>
));
FullPageSection.displayName = "Section";
export default FullPageSection;
