import { Link } from "react-router-dom";
import FullPageSection from "../layout/FullPageSection";
import HowItWorksStep from "./HowItWorksStep";
import PersonasTable from "./PersonasTable";
import { useQuery } from "@tanstack/react-query";
import { fetchExperimentById } from "src/api/experimentAPI";
import HowItWorksTable from "./HowItWorksTable";
import HowItWorksIntersectionalityTable from "./HowItWorksIntersectionalityTable";
import CaseStudyCard from "./CaseStudyCard";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import NumberedItem from "./NumberedItem";
import FakeChatWindow from "./FakeChatWindow";
import { HowItWorksChart } from "./HowItWorksChart";
import { ExperimentPersonaResult } from "src/types/models/ExperimentPersonaResult";
import Decimal from "decimal.js";
const HowItWorksMainInfo = () => {
  const experimentId = "d9431aaa-b072-41be-9da3-f1130bad0e48";

  const {
    isPending,
    error,
    data: experiment,
    status,
  } = useQuery({
    queryKey: ["experiment"],
    queryFn: () =>
      (async () => {
        return fetchExperimentById(experimentId, null);
      })(),
    enabled: true,
  });

  return (
    <>
      <FullPageSection
        className="bg-henna-background flex flex-col gap-10"
        id="home"
      >
        <div className="grid md:grid-cols-2 justify-between items-end md:items-center">
          <div className="flex flex-col gap-6 md:max-w-[500px] items-start  py-2">
            <h1 className="text-5xl font-bold mt-2">How It Works</h1>
            <h3 className="text-2xl grow-0">
              {" "}
              A key part of socially responsible use of AI is explaining the how
              and why behind what you’re doing — and so, in the spirit of
              radical transparency, that’s what we’ll do here.
            </h3>
          </div>
          <img
            src="./share-post.png"
            className="rounded-xl object-contain xl:max-h-[600px]"
          />
        </div>
        <div className="flex flex-col gap-12">
          <h3 className="text-2xl grow-0 text-center font-bold">
            There are four areas to review when it comes to our tool:
          </h3>
          <ol className="flex flex-col items-center md:flex-row gap-3 md:justify-between md:items-start w-full">
            <NumberedItem
              linkUrl="/how-it-works#research"
              linkText="1. The Research"
              description="it is based upon"
            />
            <NumberedItem
              linkUrl="/how-it-works#inputs"
              linkText="2. The Inputs"
              description="we ask from you"
            />
            <NumberedItem
              linkUrl="/how-it-works#process"
              linkText="3. The Process"
              description="we apply to those inputs"
            />
            <NumberedItem
              linkUrl="/how-it-works#outputs"
              linkText="4. The Outputs"
              description="we deliver"
            />
          </ol>
        </div>
      </FullPageSection>

      <FullPageSection
        className="flex flex-col gap-10 bg-off-white"
        id="research"
      >
        <h3 className="md:text-center text-4xl md:text-5xl font-bold">
          The Research
        </h3>
        <div className="flex flex-row justify-center">
          <img src="/divider.png" />
        </div>

        <div className="grid lg:grid-cols-2 gap-28">
          <div className="hidden lg:block">
            <img src="./programming.png"></img>
          </div>
          {/* this will be a news card */}
          <div className="flex flex-col gap-5 h -full items-start justify-center">
            <h3 className="text-3xl md:text-4xl mb-4 font-bold">
              {" "}
              Based on a Landmark Study on Bias
            </h3>
            <img src="./divider-start.png" alt="divider" />
            <h4 className="header-henna-6 font-bold">
              {
                "Are Emily and Greg More Employable than Lakisha and Jamal? \n(July 2003)"
              }
            </h4>
            <p></p>
            <p>
              In this seminal paper, economists Marianne Bertrand and Sendhil
              Mullainathan conducted a field experiment to measure racial
              discrimination in the U.S. labor market. They sent out thousands
              of resumes to employers, varying only the names to signal either
              African American (e.g., Lakisha and Jamal) or White (e.g., Emily
              and Greg) identities. The results were striking: resumes with
              White-sounding names received{" "}
              <span className="font-bold">50% more callbacks</span> than those
              with African American-sounding names, despite identical
              qualifications.
            </p>
            <p>
              This study has become a cornerstone in understanding systemic bias
              in hiring practices. We recreate this methodology in AI
              simulations, incorporating novel elements developed by our
              founder, <span className="font-bold">Arsh Haque</span>, who is a
              data scientist, an economist, and a DEI (diversity, equity, &
              inclusion) professional. We hold an exclusive license on these
              advancements, enabling us to offer a unique solution for auditing
              AI tools for bias.
            </p>
            <Link to="https://www.nber.org/papers/w9873" className="btn-henna">
              <span>Read More</span>
            </Link>
          </div>
        </div>
      </FullPageSection>

      <FullPageSection className="flex flex-col gap-10" id="inputs">
        <h3 className="md:text-center text-4xl md:text-5xl font-bold">
          The Inputs
        </h3>
        <div className="flex flex-row justify-center">
          <img src="/divider.png" />
        </div>
        <HowItWorksStep
          header="Step 1: Tell Us About Your Organization"
          subHeader="To ensure our evaluation captures your unique context, we start
          with a snapshot of who you are."
          imageLink="./business-meeting.png"
          description={[
            <li key={1}>
              <span>Sharing your </span>
              <span className="font-bold">website</span>
            </li>,
            <li key={2}>
              <span>Uploading a </span>
              <span className="font-bold">PDF </span>
              <span>of your mission statement</span>
            </li>,
            <li key={3}>
              <span>Providing your </span>
              <span className="font-bold">location </span>
              <span>and organizational objectives</span>
            </li>,
          ]}
          caseStudyHeader="Based on a website shared with us, we created the following
            (redacted for privacy):"
          caseStudy={
            <>
              <p>
                Nestled within a dynamic university ecosystem, [client name]
                isn’t just another academic department—it’s a vibrant hub where
                innovation, creativity, and interdisciplinary collaboration
                converge. Operating independently, it unites students across
                various disciplines under a singular mission: to ignite an
                entrepreneurial spirit campus-wide without being confined to
                traditional business paradigms. Unlike conventional incubators
                or venture capital firms, its purpose isn’t to fund startups or
                cater solely to business students. Instead, it empowers all
                students with the mindset and skills to turn visionary ideas
                into tangible solutions. Offering a range of programs—from
                hands-on innovation labs and mentorship networks to real-world
                problem-solving workshops—it fosters experiential learning that
                transcends the classroom. The center serves the entire student
                body eager to embrace entrepreneurship, deliberately excluding
                those seeking a typical academic path devoid of practical
                application. What sets it apart is its commitment to inclusivity
                and real-world impact, breaking down silos between disciplines
                through industry partnerships and a focus on addressing genuine
                societal challenges. The atmosphere buzzes with possibility,
                cultivating a culture where taking risks is encouraged, failures
                are seen as learning opportunities, and students are engaged not
                just as learners but as innovators ready to reshape the future.
              </p>
            </>
          }
        />
        <HowItWorksStep
          header="Step 2: Choose an Evaluation Task"
          subHeader="Bias can appear differently across various tasks. Select a task that involves assessment, such as grading a paper or evaluating a resume, and help us draft a sample by:"
          imageLink="./app-development.png"
          description={[
            <li key={1}>
              <span className="font-bold">Broadly describe </span>
              <span>the task </span>
            </li>,
            <li key={2}>
              <span>Provide a </span>
              <span className="font-bold">redacted example </span>
            </li>,
            <li key={3}>
              <span>Upload a </span>
              <span className="font-bold">template </span>
            </li>,
          ]}
          caseStudyHeader="Based on an application PDF shared with us, we created the
            following (redacted for privacy):"
          caseStudy={
            <>
              <p> </p>
              <p>
                {`[The program], hosted by [client name], supports student-led
            startups as they transition from ideas to real-world impact.
            This application serves as a screening tool to help us learn
            more about potential startups on campus. It’s not a
            competitive application; rather, it provides the basis for a
            conversation with the program's Director.`}
              </p>
            </>
          }
        />
        <HowItWorksStep
          header="Step 3: Provide Evaluation Criteria"
          subHeader="Share your evaluation criteria to align the simulations with your standards. You can:"
          imageLink="./freelance.png"
          description={[
            <li key={1}>
              <span>Provide a </span>
              <span className="font-bold">evaluation rubric </span>
            </li>,
            <li key={2}>
              <span>Share a </span>
              <span className="font-bold">graded sample </span>
            </li>,
            <li key={3}>
              <span className="font-bold">Leave this step to us, </span>
              <span>
                {"and we'll use standard criteria relevant to your task"}
              </span>
            </li>,
          ]}
          caseStudyHeader="The client deferred to us to create a sample submission and evaluate it."
          caseStudy={
            <>
              <p>
                {`Within a dynamic university's innovative ecosystem, a team of design and psychology students has launched "MindfulSpace," a startup set to transform mental wellness through immersive virtual reality experiences. Addressing the growing challenge of stress and anxiety among young adults, MindfulSpace offers a VR platform that transports users to peaceful environments, guiding them through personalized mindfulness and relaxation sessions. With a functional prototype embraced by over 300 beta testers, the platform is earning praise for its impact on well-being. Although not yet generating revenue, their vision has attracted a $40,000 grant from a notable mental health foundation, validating their mission’s importance. They are preparing for a public launch next quarter, aiming to onboard 5,000 users and exploring partnerships with educational institutions and wellness centers. As they join an incubator program, MindfulSpace is not just launching a product—it’s pioneering a new frontier in accessible mental health care.`}
              </p>
              <p>Score: 886/1000</p>
            </>
          }
        />
        <HowItWorksStep
          header="Step 4: Define the Population"
          subHeader="Who is being evaluated matters. Inform us about any demographic elements relevant to your concern, such as:"
          imageLink="./teamwork.png"
          description={[
            <li key={1}>
              <span className="font-bold">Race </span>
            </li>,
            <li key={2}>
              <span className="font-bold">Gender</span>
            </li>,
            <li key={3}>
              <span className="font-bold">Place of Origin</span>
            </li>,
            <li key={4}>
              <span className="font-bold">Other attributes</span>
              <span> you care about</span>
            </li>,
          ]}
          caseStudyHeader="Based on a discussion with our client, we identified 12 key attributes about the population they serve based on 3 areas: race, gender, and place of origin. From those attributes, we then created 36 personas."
          caseStudy={
            <>
              <PersonasTable />
            </>
          }
        />
        <HowItWorksStep
          header="Step 5: Select AI Tools for Auditing"
          subHeader="Let us know which AI models you're using or interested in, such as:"
          imageLink="./talking-people-with-ovals.png"
          description={[
            <li key={1}>
              <span className="font-bold">ChatGPT </span>
            </li>,
            <li key={2}>
              <span className="font-bold">Grok</span>
            </li>,
            <li key={3}>
              <span className="font-bold">Claude</span>
            </li>,
          ]}
          caseStudyHeader="Here, the client chose to evaluate the following models:"
          caseStudy={
            <ol className="list-disc list-inside text-lg font-bold">
              <li>Llama 3.1 8b</li>
              <li>GPT 4o</li>
              <li>Claude 3 Haiku</li>
            </ol>
          }
        />
      </FullPageSection>
      <FullPageSection
        className="bg-off-white flex flex-col gap-10"
        id="process"
      >
        <h3 className="md:text-center text-5xl md:text-6xl font-bold">
          The Process
        </h3>
        <div className="flex flex-col items-center justify-center gap-5">
          <span className="block text-xl">
            We then run 1,000+ simulations across the selected AI tools and
            characteristics.
          </span>

          <div className="flex flex-row justify-center">
            <img src="/divider.png" />
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-12 lg:gap-28">
          <div className="flex flex-col gap-5 h -full items-start justify-center">
            <h3 className="text-3xl md:text-4xl mb-4 font-bold">
              {" "}
              Each Simulation:
            </h3>
            <img src="./divider-start.png" alt="divider" />
            <ul className="list-disc list-inside flex flex-col gap-4 text-xl font-bold">
              <li>Is a conversation with one llm</li>
              <li>Represents one Persona</li>
              <li>Provides a sample task and a sample score</li>
              <li>
                Provides the same evaluation task as every other simulation
              </li>
              <li>Results in a numerical score</li>
            </ul>
          </div>
          <div className="flex justify-center">
            <FakeChatWindow />
          </div>
        </div>
      </FullPageSection>
      <FullPageSection className="flex flex-col gap-10" id="outputs">
        <div className="flex flex-col gap-3">
          <h3 className="text-center text-4xl md:text-5xl font-bold">
            The Outputs
          </h3>
          <div className="flex flex-row justify-center">
            <img src="/divider.png" />
          </div>
        </div>
        <h3 className="text-center text-xl">
          Finally, we present you with a comprehensive report on AI bias for
          your use case. The following tables are an example of the data our
          reports are based on.
        </h3>
        <HowItWorksChart experiment={experiment} />
        <Tabs defaultValue="persona-results" className="w-full">
          <TabsList className="grid w-full md:grid-cols-2 mb-12 md:mb-4">
            <TabsTrigger value="persona-results">Persona Scores</TabsTrigger>
            <TabsTrigger value="characteristic-results">
              Characteristics Scores
            </TabsTrigger>
          </TabsList>
          <TabsContent value="persona-results">
            <HowItWorksIntersectionalityTable data={experiment} />
          </TabsContent>
          <TabsContent value="characteristic-results">
            <HowItWorksTable data={experiment} />
          </TabsContent>
        </Tabs>
      </FullPageSection>
    </>
  );
};
export default HowItWorksMainInfo;
