import {
  Binary,
  Boxes,
  ChartColumn,
  Check,
  FlaskConical,
  Home,
  Inbox,
  ListMinus,
  ListTree,
  PersonStanding,
  Play,
  ReceiptText,
  Search,
  Settings,
} from "lucide-react";

import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "src/components/ui/sidebar";
import { SidebarType } from "../layout/SidebarLayout";
import { Experiment } from "src/types/models/Experiment";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import routes from "src/routes";

export function AppSidebar({
  sidebarType,
  experimentId,
}: {
  sidebarType: SidebarType;
  experimentId?: string;
}) {
  const experimentsItems = [
    {
      title: "Home",
      url: routes.home,
      icon: Home,
    },
    {
      title: "View Experiments",
      url: routes.experiment.base,
      icon: ListTree,
    },
    {
      title: "Create Experiment",
      url: routes.experiment.create,
      icon: FlaskConical,
    },
  ];
  const experimentItems = [
    {
      title: "Home",
      url: routes.home,
      icon: Home,
    },
    {
      title: " View Experiments",
      url: routes.experiment.base,
      icon: ListTree,
    },
    {
      title: " Experiment Overview",
      url: routes.experiment.show(experimentId ?? ""),
      icon: FlaskConical,
    },
    {
      title: "Edit Experiment Basics",
      url: routes.experiment.editExperimentBasics(experimentId ?? ""),
      icon: ListTree,
    },
    {
      title: "Dimensions and Characteristics",
      url: routes.experiment.dimensionsAndCharacteristicsForExperiment(
        experimentId ?? "",
      ),
      icon: Boxes,
    },
    {
      title: "Personas",
      url: routes.experiment.personasForExperiment(experimentId ?? ""),
      icon: PersonStanding,
    },
    {
      title: "Llms",
      url: routes.experiment.llmsForExperiment(experimentId ?? ""),
      icon: Binary,
    },
    {
      title: "Run Experiment",
      url: routes.experiment.runExperiment(experimentId ?? ""),
      icon: Play,
    },
    {
      title: "Experiment Results",
      url: routes.experiment.experimentResults(experimentId ?? ""),
      icon: ChartColumn,
    },
  ];
  return (
    <Sidebar>
      <SidebarContent className="bg-gray-100">
        <SidebarGroup>
          {/* <SidebarGroupLabel>Application</SidebarGroupLabel> */}
          <SidebarGroupContent>
            <SidebarMenu>
              {sidebarType === SidebarType.Experiment &&
                experimentItems.map((item) => (
                  <SidebarMenuItem key={item.title} className="">
                    <SidebarMenuButton className="">
                      <NavLink
                        to={item.url}
                        className={({ isActive }) =>
                          isActive
                            ? "font-bold text-wrap flex gap-1 h-auto items-center"
                            : "text-wrap flex gap-1 h-auto items-center"
                        }
                        end
                      >
                        <item.icon className="text-henna-dark-green" />
                        <span className="text-henna-dark-green !whitespace-normal">
                          {item.title}
                        </span>
                        {/* <Check className="font-black" /> */}
                      </NavLink>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                ))}
              {sidebarType === SidebarType.Experiments &&
                experimentsItems.map((item) => (
                  <SidebarMenuItem key={item.title} className="text-wrap">
                    <SidebarMenuButton className="text-wrap">
                      <NavLink
                        to={item.url}
                        end
                        className={({ isActive }) =>
                          isActive
                            ? "font-bold text-wrap flex gap-1"
                            : "text-wrap flex gap-1"
                        }
                      >
                        <item.icon className="text-henna-dark-green" />
                        <span className="text-henna-dark-green !whitespace-normal">
                          {item.title}
                        </span>
                      </NavLink>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
}
