import React from "react";

import HomePageLayout from "../components/layout/HomePageLayout";
const NotFoundPage: React.FC = () => {
  return (
    <HomePageLayout pageName="Not Found">
      <section className="bg-henna-background py-40 h-screen">
        <div className="flex flex-col gap-6 items-center">
          <h1 className="header-henna-2">404 Not Found</h1>
          <p>{"The page you're looking for does not exist."}</p>
        </div>
      </section>
    </HomePageLayout>
  );
};

export default NotFoundPage;
