import axios, { AxiosError } from "axios";
import { LlmModel } from "../types/models/LlmModel";

const backendURL = process.env.REACT_APP_BACKEND_URL;

export async function createLlmModel(
  llmModelInfo: Partial<LlmModel>,
  token: string | null,
): Promise<LlmModel> {
  const response = await axios.post(
    `${backendURL}/llm-model/`,
    { ...llmModelInfo },
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.llmModel) {
    return response?.data?.llmModel;
  } else {
    throw new Error("did not return a llmModel");
  }
}
export async function fetchLlmModels(
  token: string | null,
): Promise<LlmModel[]> {
  try {
    if (!token) {
      throw new Error("Auth token was not provided");
    }
    const response = await axios.get(`${backendURL}/llm-model`, {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.data?.llmModels) {
      return response?.data?.llmModels;
    } else {
      throw new Error("did not return a llmModel list");
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const message = error.response?.data.message;
      throw new Error(message);
    } else {
      throw new Error(error.message);
    }
  }
}
export async function fetchLlmModelById(
  llmModelId: string,
  token: string | null,
): Promise<LlmModel> {
  try {
    // if (!token) {
    //   throw new Error("Auth token was not provided");
    // }
    const response = await axios.get(`${backendURL}/llm-model/${llmModelId}`, {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token ?? ""}`,
      },
    });
    if (response?.data?.llmModel) {
      return response?.data?.llmModel as LlmModel;
    } else {
      throw new Error("did not return a llmModel");
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const message = error.response?.data.message;
      throw new Error(message);
    } else {
      throw new Error(error.message);
    }
  }
}

export async function updateLlmModel(
  llmModelId: string,
  updateData: {
    title?: string;
    useTemplate: boolean;
    llmModelType: string;
    prototypeText: string;
    iterations?: number;
    description?: string;
    llmPrompt?: string;
    sampleText?: number;
    sampleScore?: number;
    characteristicIds?: string[];
    llmModelIds?: string[];
  },
  token: string | null,
): Promise<LlmModel> {
  if (!token) {
    throw new Error("Auth token was not provided");
  }
  const response = await axios.put(
    `${backendURL}/llm-model/${llmModelId}`,
    updateData,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.llmModel) {
    return response.data.llmModel;
  } else {
    throw new Error("Failed to update llmModel");
  }
}

export async function deleteLlmModel(
  llmModelId: string,
  token: string,
): Promise<void> {
  if (!token) {
    throw new Error("Auth token was not provided");
  }
  const response = await axios.delete(`${backendURL}/llm-model/${llmModelId}`, {
    headers: {
      "Content-Type": "application/json", // Example header
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw new Error("Failed to delete llmModel");
  }
}
