import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
  CardHeader,
} from "./ui/card";
export interface TaskCardProps {
  header: string;
  description: string;
  bodyHeader: string;
  firstItemText: string;
  secondItemText?: string;
  thirdItemText?: string;
  firstItemUrl: string;
  secondItemUrl?: string;
  thirdItemUrl?: string;
  step: string;
}
const TaskCard = (props: TaskCardProps) => {
  // Close dropdown if clicked outside
  const {
    header,
    description,
    bodyHeader,
    firstItemText,
    thirdItemText,
    firstItemUrl,
    secondItemText,
    thirdItemUrl,
    secondItemUrl,
    step,
  } = props;

  return (
    <div className="h-full">
      <Card className="relative max-w-[460px] border border-henna-green-accent rounded-xl h-full">
        <div className="bg-white rounded-xl p-6">
          <CardContent className="bg-henna-background-2 rounded-xl p-4">
            <div className="flex flex-col bg-off-white-grey rounded-xl p-4 gap-4">
              <h3 className="header-henna-5 font-bold text-center">
                {bodyHeader}
              </h3>
              <div className="flex items-center justify-start bg-white rounded-xl gap-4">
                <img src={firstItemUrl} className="max-h-[62px]" />
                <div className="">
                  <span>{firstItemText}</span>
                  {firstItemText && (
                    <img
                      src="/progress-bars-yellow-grey.png"
                      className="w-16"
                    />
                  )}
                  {!firstItemText && (
                    <img
                      className="pr-4 scale-y-150"
                      src="/progress-bars-long-grey.png"
                    />
                  )}
                </div>
              </div>
              <div className="flex items-center justify-start bg-white rounded-xl gap-4">
                <img src={secondItemUrl} className="w-16" />
                <div>
                  <span>{secondItemText}</span>
                  {secondItemText && (
                    <img src="/progress-bars-green-grey.png" className="w-16" />
                  )}
                  {!secondItemText && (
                    <img
                      src="/progress-bars-long-grey.png"
                      className="pr-4 scale-y-150"
                    />
                  )}
                </div>
              </div>
              <div className="flex items-center justify-start bg-white rounded-xl gap-4">
                <img src={thirdItemUrl} className="w-16" />
                <div>
                  <span>{thirdItemText}</span>
                  {secondItemText && (
                    <img src="/progress-bars-grey.png" className="w-16" />
                  )}
                  {!secondItemText && (
                    <img
                      src="/progress-bars-long-grey.png"
                      className="pr-4 scale-y-150"
                    />
                  )}
                </div>
              </div>
            </div>
          </CardContent>
          <CardHeader>
            <CardTitle className="text-center">{header} </CardTitle>
            <CardDescription className="text-center">
              {description}
            </CardDescription>
          </CardHeader>
        </div>
      </Card>
      <div className="flex items-center justify-center w-full relative bottom-5">
        <div className="bg-black font-white w-10 h-10 rounded-3xl text-white flex justify-center items-center">
          <span className=" font-bold text-xl">{step}</span>
        </div>
      </div>
    </div>
  );
};
// Figure out how to style the SignOutButton component later so we don't have to wrap it with a div for styling
export default TaskCard;
