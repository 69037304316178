"use client";

import { Bar, BarChart, CartesianGrid, XAxis, Cell, LabelList } from "recharts";

import { ChartConfig, ChartContainer } from "src/components/ui/chart";
import { ChartTooltip, ChartTooltipContent } from "src/components/ui/chart";
import { ChartLegend, ChartLegendContent } from "src/components/ui/chart";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Tooltip } from "../ui/tooltip";
import Decimal from "decimal.js";
import { Experiment } from "src/types/models/Experiment";
import { ExperimentPersonaResult } from "src/types/models/ExperimentPersonaResult";
("use client");

const chartConfig = {
  llm: {
    label: "Visitors",
  },
} satisfies ChartConfig;
export function HowItWorksChart(props: { experiment: Experiment | undefined }) {
  const { experiment } = props;

  // Assume 'results' is your initial array of ExperimentPersonaResult
  const personaResults = experiment?.personaResults;
  type LlmGroups = {
    [key: string]: ExperimentPersonaResult[];
  };
  // Step 1: Group the results by LLM
  const llmGroups = personaResults?.reduce((groups: LlmGroups, result) => {
    const llmName = result?.llmModel?.friendlyName || result.llmModelId;
    if (!groups[llmName]) {
      groups[llmName] = [];
    }
    groups[llmName].push(result);
    return groups;
  }, {});

  // Step 2: Calculate min and max values for each LLM
  const chartData = Object.entries(llmGroups ?? {}).map(([llmName, group]) => {
    let min = new Decimal(Infinity);
    let max = new Decimal(-Infinity);
    let minPersona = "";
    let maxPersona = "";

    group.forEach((result) => {
      const value = new Decimal(result.scoreDistanceFromLlmMedian);
      76;
      const personaName = `${result.persona?.name}: ${result.persona?.characteristics?.map((characteristic) => characteristic.name).join(", ")}`;

      if (value.lessThan(min)) {
        min = value;
        minPersona = personaName;
      }

      if (value.greaterThan(max)) {
        max = value;
        maxPersona = personaName;
      }
    });

    return {
      Llm: llmName,
      min: min.toDecimalPlaces(6).toNumber(),
      max: max.toDecimalPlaces(6).toNumber(),
      minPersona,
      maxPersona,
    };
  });

  console.log(chartData);
  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-center">
          Most Preferred and Least Preferred Personas Per LLM
        </CardTitle>
        {/* <CardDescription>January - June 2024</CardDescription> */}
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis dataKey="Llm" />
            <ChartTooltip
              formatter={(value, name, props) => {
                const { payload, dataKey } = props;
                let persona;

                if (dataKey === "min") {
                  persona = payload.minPersona ?? "unknown";
                } else if (dataKey === "max") {
                  persona = payload.maxPersona ?? "unknown";
                }

                return [
                  persona as string,
                  `${dataKey === "max" ? "Most Preferred " : "Least Preferred "} Persona`,
                ];
              }}
            />
            <Bar dataKey="max" fill="hsl(var(--chart-2))" name="Max">
              <LabelList position="top" fillOpacity={1} />
            </Bar>
            <Bar dataKey="min" fill="hsl(var(--chart-1))" name="Min">
              <LabelList position="top" fillOpacity={1} />
            </Bar>
            {/* Bar for 'max' values */}
          </BarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 text-sm">
        <div className="flex gap-2 font-medium leading-none">
          {`*Results represent the distance from the median, which is calcualted by taking the median score for
        a persona for an llm, minus that llm's median score across all
        personas. The above graph displays the personas that have the greatest distance from the median for each llm. `}{" "}
        </div>
        {/* <div className="leading-none text-muted-foreground">
          Showing total visitors for the last 6 months
        </div> */}
      </CardFooter>
    </Card>
  );
}
