import { SidebarProvider, SidebarTrigger } from "src/components/ui/sidebar";
import { AppSidebar } from "src/components/ui/app-sidebar";
import { Experiment } from "src/types/models/Experiment";
import { Outlet, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@clerk/clerk-react";
import { fetchExperimentById } from "src/api/experimentAPI";
export enum SidebarType {
  Experiments = "Experiments",
  Experiment = "Experiment",
}
export default function SidebarLayout() {
  const { experimentId } = useParams();
  let sidebarType = SidebarType.Experiments;
  if (experimentId) {
    sidebarType = SidebarType.Experiment;
  }
  const { getToken } = useAuth();

  const { data: experiment } = useQuery<Experiment | undefined, Error>({
    queryKey: ["experiment", experimentId],
    queryFn: () =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error("Your authentication token could not be accessed");
        }
        if (!experimentId) {
          return;
        }
        return fetchExperimentById(experimentId, freshToken);
      })(),
  });

  return (
    <SidebarProvider>
      <AppSidebar sidebarType={sidebarType} experimentId={experimentId} />
      <main className="w-full">
        <SidebarTrigger />
        <Outlet context={{ experiment }} />
      </main>
    </SidebarProvider>
  );
}
