import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import exp from "constants";
import { useOutletContext, useParams } from "react-router-dom";
import { fetchExperimentById, fetchExperiments } from "src/api/experimentAPI";
import { ExperimentCard } from "src/components/experiments/ExperimentCard";
import { RunExperiment } from "src/components/experiments/RunExperiment";
import { SetupDimensionsAndCharacteristicsForExperiment } from "src/components/experiments/SetupDimensionsAndCharacteristicsForExperiment";
import { SetupLlmsForExperiment } from "src/components/experiments/SetupLlmsForExperiment";
import { SetupPersonasForExperiment } from "src/components/experiments/SetupPersonasForExperiment";
import { PageContainer } from "src/components/layout/PageContainer";
import SideBarLayout, {
  SidebarType,
} from "src/components/layout/SidebarLayout";
import { Experiment } from "src/types/models/Experiment";

export default function RunExperimentPage() {
  const { experimentId } = useParams();
  const { experiment } = useOutletContext<{
    experiment: Experiment | undefined;
  }>();

  return (
    <PageContainer>
      {experiment && <RunExperiment experiment={experiment} />}
    </PageContainer>
  );
}
