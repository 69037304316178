import { Experiment, ExperimentStatus } from "src/types/models/Experiment";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";
import { CardBody } from "react-bootstrap";
import { InputContainer } from "../forms/InputContainer";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "@clerk/clerk-react";
import { createDimension, fetchDimensions } from "src/api/dimensionAPI";
import { Dimension } from "src/types/models/Dimension";
import { Checkbox } from "../ui/checkbox";
import { useEffect, useState } from "react";
import { Label } from "../ui/label";
import { Combobox } from "../ui/combobox";
import { runExperiment } from "src/api/experimentAPI";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import { useToast } from "../hooks/use-toast";
import routes from "src/routes";

type SetupLlmsForExperiment = {
  experiment: Experiment;
};

export const RunExperiment: React.FC<SetupLlmsForExperiment> = ({
  experiment,
}) => {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [selectedModelIds, setSelectedModelIds] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const updateExperimentMutation = useMutation({
    mutationFn: async () => {
      const freshToken = await getToken();
      if (!freshToken) {
        throw new Error(
          "Your experiment could not be run because your account could not be authenticated.",
        );
      }
      return await runExperiment(experiment.id, freshToken);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["experiment", experiment.id],
      });
      toast({
        title: "This experiment has successfully started",
        description:
          "Please allow up to 2 hours for the experiment to complete.",
      });
      navigate(routes.experiment.experimentResults(experiment.id));
    },
    onError: (error: Error) => {
      console.error("Message creation error", error);
      toast({
        title: "There was an unexpected error when starting this experiment",
        variant: "destructive",
      });
    },
  });

  console.log("experiment", experiment);

  return (
    <Card>
      <CardHeader className="p-1 bg-henna-green-accent text-off-white rounded-t-sm">
        Run {experiment.title}
      </CardHeader>
      <CardContent className="flex flex-col gap-4">
        <p className="helper-text text-sm font-bold">Instructions</p>
        <p className="helper-text text-sm">
          {`Before running this experiment, you should review all the relevant information. For an experiment to run, you must have at least one Persona, at least one LLM, and at least one iteration.`}
        </p>
        <p className="helper-text text-sm font-bold">
          How long will this take?
        </p>
        <p className="helper-text text-sm">
          {`In order to estimate the length of time required, we calculate the number of simulations using a formula shown below. For smaller experiments (under 100 iterations), this may take a minute or two.  If you have over 1000 iterations, it may take 30 minutes to 2 hours to complete.`}
        </p>

        {experiment?.llmModels && experiment.llmModels.length > 0 ? (
          <div>
            <h4 className="header-henna-6">LLMs</h4>
            {experiment.llmModels?.map((llm) => (
              <div key={llm.id} className="">
                <span>
                  {llm.friendlyName} (vendor: {llm.vendor?.name})
                </span>
              </div>
            ))}
          </div>
        ) : (
          <span>You have not selected any Llms for this experiment</span>
        )}
        {experiment?.personas && experiment.personas.length > 0 && (
          <div>
            <h4 className="header-henna-6">Personas</h4>

            {experiment.personas?.map((persona) => (
              <div key={persona.id} className="">
                <span>{persona.name}</span>
                <div className="flex gap-1 ml-4">
                  {persona.characteristics?.map((characteristic) => (
                    <div key={characteristic.id}>
                      <span>{characteristic.name},</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
        <h4 className="header-henna-6">Iterations: {experiment.iterations}</h4>
        <h4 className="header-henna-6">Number of Simulations</h4>
        <span>
          Number of simulations required to run this experiment:{" "}
          {experiment.personas?.length} personas *{" "}
          {experiment.llmModels?.length} LLM models * {experiment.iterations}{" "}
          iterations ={" "}
          {(experiment.personas?.length ?? 0) *
            (experiment.llmModels?.length ?? 0) *
            experiment.iterations}
        </span>

        {experiment.status === ExperimentStatus.SETTING_UP && (
          <Button onClick={() => updateExperimentMutation.mutate()}>
            Run Experiment
          </Button>
        )}
        {experiment.status === ExperimentStatus.SUCCESSFUL && (
          <span className="header-henna-6">
            You cannot run this experiment because it has already been run
            successfully.
          </span>
        )}
        {experiment.status === ExperimentStatus.RUNNING && (
          <span className="header-henna-6">
            You cannot run this experiment because it is currently running.
          </span>
        )}
        {experiment.status === ExperimentStatus.UNSUCCESSFUL && (
          <span className="header-henna-6">
            You cannot run this experiment because you have already tried
            running it.
          </span>
        )}
      </CardContent>
    </Card>
  );
};
