import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/layout/ChatLayout";

import CollectionList from "../../components/chat/CollectionList";
import TeamList from "../../components/chat/TeamList";
import { MyContext } from "../../context/Context";
import { User } from "../../types/models/User";
import TeamDashboard from "../../components/chat/TeamDashboard";
import { Team } from "../../types/models/Team";
import ButtonOutline from "../../components/ButtonOutline";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "@clerk/clerk-react";
import { createToken } from "../../api/userAPI";
import {
  fetchOrganizationJoinRequests,
  updateJoinRequest,
} from "../../api/joinRequestAPI";
import { JoinRequest } from "../../types/models/JoinRequest";
import { Organization } from "../../types/models/Organization";

// interface AsideProps {
//   currentOrganizationId: string;
//   handleChangeConversationId: (conversationId: string) => void;
//   handleChangeOrganizationId: (organizationId: string) => void;
// }

// The Aside component
interface AsideProps {
  user?: User;
  handleClickTeam: (team: Team) => void;
  handleClickValues: () => void;
  isPending: boolean;
  handleClickRequests: () => void;
  handleShowInviteToken: () => void;
}
const Aside: React.FC<AsideProps> = ({
  user,
  handleClickTeam,
  isPending,
  handleClickValues,
  handleClickRequests,
  handleShowInviteToken,
}) => (
  <div className="flex flex-col gap-2">
    <ButtonOutline
      text="Values/General"
      handleClick={handleClickValues}
    ></ButtonOutline>
    <ButtonOutline
      text="Requests To Join"
      handleClick={handleClickRequests}
    ></ButtonOutline>
    <ButtonOutline
      text="Invite Tokens"
      handleClick={handleShowInviteToken}
    ></ButtonOutline>
    <button></button>
    {user?.selectedOrganizationId && (
      <TeamList
        organizationId={user.selectedOrganizationId}
        handleClickTeam={handleClickTeam}
        isPending={isPending}
      />
    )}
  </div>
);
const TeamPage: React.FC = () => {
  // const [currentConversationId, setCurrentConversationId] = useState('')
  const [currentOrganization, setCurrentOrganization] = useState<
    Organization | null | undefined
  >(null);
  const [currentTeam, setCurrentTeam] = useState<Team | null | undefined>(null);

  const [showValues, setShowValues] = useState(true);
  const [showRequests, setShowRequests] = useState(false);
  const [showCreateInviteToken, setShowCreateInviteToken] = useState(false);
  const { user } = useContext(MyContext);
  const [inviteURL, setInviteURL] = useState("");
  const { getToken } = useAuth();

  useEffect(() => {
    setCurrentTeam(user?.selectedTeam);
    setCurrentOrganization(user?.selectedOrganization);
  }, [user]);

  const handleClickTeam = (team: Team) => {
    setCurrentTeam(team);
  };
  const handleClickValues = () => {
    setShowValues(true);
    setShowCreateInviteToken(false);
    setShowRequests(false);
  };
  const handleClickRequests = () => {
    setShowRequests(true);
    setShowCreateInviteToken(false);
    setShowValues(false);
  };
  const handleShowCreateInviteToken = () => {
    setShowCreateInviteToken(true);
    setShowValues(false);
    setShowRequests(false);
  };
  const queryClient = useQueryClient();
  const renderAside = () => (
    <Aside
      user={user ?? undefined}
      handleClickTeam={handleClickTeam}
      handleClickValues={handleClickValues}
      handleClickRequests={handleClickRequests}
      handleShowInviteToken={handleShowCreateInviteToken}
      // fix me- eventually set up this as a pending option depending on handleclickTeam
      isPending={false}
      // currentOrganizationId={currentOrganizationId}
      // handleChangeConversationId={setCurrentConversationId}
      // handleChangeOrganizationId={setCurrentOrganizationId}
    />
  );
  const {
    isPending,
    error,
    data: requests,
  } = useQuery({
    queryKey: ["joinRequests"],
    queryFn: () =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error("could not generate a token for this request");
        }
        return fetchOrganizationJoinRequests(freshToken);
      })(),
  });
  const inviteTokenMutation = useMutation({
    mutationFn: (info: { organizationId: string; teamId: string }) =>
      (async () => {
        const fetchedToken = await getToken();
        if (!fetchedToken) {
          throw new Error("Your token could not be accessed");
        }
        return createToken(fetchedToken, info.organizationId, info.teamId);
      })(),
    onSuccess: (url: string) => {
      setInviteURL(url);
    },

    onError: (error: Error) => {
      console.error("Conversation error", error);
    },
  });
  const joinRequestMutation = useMutation({
    mutationFn: (info: { requestId: string; newStatus: string }) =>
      (async () => {
        const fetchedToken = await getToken();
        if (!fetchedToken) {
          throw new Error("Your token could not be accessed");
        }
        return updateJoinRequest(fetchedToken, info.requestId, {
          newStatus: info.newStatus,
        });
      })(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["joinRequests"] });
    },

    onError: (error: Error) => {
      console.error("Conversation error", error);
    },
  });
  const handleCreateInviteToken = () => {
    if (currentOrganization?.id && currentTeam?.id) {
      inviteTokenMutation.mutate({
        organizationId: currentOrganization.id,
        teamId: currentTeam.id,
      });
    }
  };

  const handleUpdateRequest = (
    e: React.MouseEvent<HTMLElement>,
    request: JoinRequest,
    status: string,
  ) => {
    joinRequestMutation.mutate({ requestId: request.id, newStatus: status });
  };
  return (
    <Layout aside={renderAside}>
      <div className="flex flex-col gap-2 w-full p-2 m-2">
        <h1 className="text-2xl">Team Page</h1>
        {showCreateInviteToken && (
          <div>
            {(!currentOrganization || !currentTeam) && (
              <span>
                You must select a team from the left before creating a token.
              </span>
            )}
            {currentOrganization && currentTeam && (
              <div>
                <span>
                  The button below will create an invite token for{" "}
                  {currentOrganization?.title} {currentTeam?.title}. If you want
                  to create a token for a different team, select the team on the
                  left.
                </span>
                {currentOrganization && currentTeam && (
                  <ButtonOutline
                    text="Create Invite Token"
                    handleClick={handleCreateInviteToken}
                  ></ButtonOutline>
                )}
                <br />
                {inviteURL && (
                  <span className="break-words break-all">{inviteURL}</span>
                )}
              </div>
            )}
          </div>
        )}

        {showRequests && (
          <div>
            <h3 className="text-lg">These are your pending requests: </h3>
            {requests
              ?.filter((request) => request.status === "PENDING")
              ?.map((request, index) => (
                <div
                  key={index}
                  className="border-2 border-black p-2 flex flex-col gap-3"
                >
                  <span>
                    {request.creator?.email} {request.creator?.firstName}{" "}
                    {request.creator?.lastName} is requesting to join
                    Organization: {request.organization.title} and Team:{" "}
                    {request.team.title}
                  </span>
                  <div className="flex gap-2 justify-between">
                    <button
                      className="btn-henna"
                      onClick={(e) =>
                        handleUpdateRequest(e, request, "approved")
                      }
                    >
                      Approve this request
                    </button>
                    <button
                      className="btn-henna"
                      onClick={(e) =>
                        handleUpdateRequest(e, request, "rejected")
                      }
                    >
                      Reject this request
                    </button>
                    {joinRequestMutation.isPending && (
                      <p>Your update is pending...</p>
                    )}
                    {joinRequestMutation.isError && (
                      <p>There was an error...</p>
                    )}
                    {joinRequestMutation.isSuccess && (
                      <p>Your update has been processed successfully.</p>
                    )}
                  </div>
                </div>
              ))}
            <h3 className="text-lg">
              These are the approved requests for your organization:{" "}
            </h3>
            {requests
              ?.filter((request) => request.status === "APPROVED")
              ?.map((request, index) => (
                <div
                  key={index}
                  className="border-2 border-black p-2 flex flex-col gap-3"
                >
                  <span>
                    {request?.responder?.email} approved the following request:{" "}
                    {request.creator?.email} {request.creator?.firstName}{" "}
                    {request.creator?.lastName} is requesting to join
                    Organization: {request.organization.title} and Team:{" "}
                    {request.team.title}
                  </span>
                </div>
              ))}
            <h3 className="text-lg">
              These are the rejected requests for your organization:{" "}
            </h3>
            {requests
              ?.filter((request) => request.status === "REJECTED")
              ?.map((request, index) => (
                <div
                  key={index}
                  className="border-2 border-black p-2 flex flex-col gap-3"
                >
                  <span>
                    {request?.responder?.email} rejected the following request:{" "}
                    {request.creator?.email} {request.creator?.firstName}{" "}
                    {request.creator?.lastName} is requesting to join
                    Organization: {request.organization.title} and Team:{" "}
                    {request.team.title}
                  </span>
                </div>
              ))}
          </div>
        )}
        {showValues && (!currentOrganization || !currentTeam) && (
          <span>
            You must select a team from the left in order to view your team
            dashboard.
          </span>
        )}
        {showValues && currentOrganization && currentTeam && (
          <TeamDashboard
            organizationId={currentOrganization.id}
            teamId={currentTeam.id}
          />
        )}
        {showValues && (
          <div>
            <CollectionList></CollectionList>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default TeamPage;
