import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { fetchExperiments } from "src/api/experimentAPI";
import { ExperimentCard } from "src/components/experiments/ExperimentCard";
import { PageContainer } from "src/components/layout/PageContainer";
import SideBarLayout, {
  SidebarType,
} from "src/components/layout/SidebarLayout";
import { Experiment } from "src/types/models/Experiment";

export default function ListExperimentsPage() {
  const { getToken } = useAuth();
  const { data: experiments } = useQuery<Experiment[], Error>({
    queryKey: ["experiments"],
    queryFn: () =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error("Your authentication token could not be accessed");
        }
        return fetchExperiments(freshToken);
      })(),
  });
  return (
    <PageContainer>
      <div>Your Experiments</div>
      <div className="flex flex-col gap-4 p-8">
        {experiments?.map((experiment) => (
          <ExperimentCard experiment={experiment} key={experiment.id} />
        ))}
      </div>
    </PageContainer>
  );
}
