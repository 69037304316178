import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import personas from "../../helper/personas.json";
const PersonasTable = () => {
  return (
    <Table>
      <TableHeader>
        <TableRow className="">
          <TableHead className="p-1">Name</TableHead>
          <TableHead className="p-1">Race</TableHead>
          <TableHead className="p-1">Gender</TableHead>
          <TableHead className="p-1">Place of Origin</TableHead>
          <TableHead className="p-1">Description</TableHead>
        </TableRow>
      </TableHeader>

      <TableBody>
        {personas.map((persona, idx) => {
          return (
            <TableRow key={idx} className="gap-1">
              <TableCell className="p-1">{persona.name}</TableCell>
              <TableCell className="p-1">{persona.race}</TableCell>
              <TableCell className="p-1">{persona.gender}</TableCell>
              <TableCell className="p-1">{persona.placeOfOrigin}</TableCell>
              <TableCell className="p-1">{persona.description}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
export default PersonasTable;
