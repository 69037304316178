import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { useOutletContext, useParams } from "react-router-dom";
import { fetchExperimentById, fetchExperiments } from "src/api/experimentAPI";
import { CreateEditExperimentForm } from "src/components/experiments/CreateEditExperimentForm";
import { ExperimentCard } from "src/components/experiments/ExperimentCard";
import { ShowExperiment } from "src/components/experiments/ShowExperiment";
import { PageContainer } from "src/components/layout/PageContainer";
import SideBarLayout, {
  SidebarType,
} from "src/components/layout/SidebarLayout";
import { Experiment } from "src/types/models/Experiment";

export default function EditExperimentPage() {
  const { experimentId } = useParams();
  const { experiment } = useOutletContext<{
    experiment: Experiment | undefined;
  }>();

  return (
    <PageContainer>
      <div>Edit Experiment: {experiment?.title}</div>
      {!experiment && <span>This experiment does not exist</span>}
      {experiment && <CreateEditExperimentForm experiment={experiment} />}
    </PageContainer>
  );
}
