import { useQuery } from "@tanstack/react-query";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { fetchExperimentById } from "src/api/experimentAPI";
import { Experiment } from "src/types/models/Experiment";
import { DataTable } from "./howItWorksDataTable/data-table";
import { columns } from "./howItWorksDataTable/persona-columns";
const HowItWorksIntersectionalityTable = (props: {
  data: Experiment | null | undefined;
}) => {
  const { data } = props;

  return (
    <div>
      <h3 className="text-lg text-center font-bold">
        Median Persona Score for each LLM
      </h3>
      <span>
        {`*The variation from median is calcualted by taking the median score for
        a persona for an llm, minus that llm's median across all
        persona.  Positive means more preferred by the llm; negative means less preferred. A score of 0 means that no bias is detected.
        `}
      </span>

      <DataTable columns={columns} data={data?.personaResults ?? []} />
    </div>
  );
};
export default HowItWorksIntersectionalityTable;
