import axios, { AxiosError } from "axios";
import { Dimension } from "../types/models/Dimension";

const backendURL = process.env.REACT_APP_BACKEND_URL;

export async function createDimension(
  dimensionInfo: Partial<Dimension>,
  token: string | null,
): Promise<Dimension> {
  const response = await axios.post(
    `${backendURL}/dimension/`,
    { ...dimensionInfo },
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.dimension) {
    return response?.data?.dimension;
  } else {
    throw new Error("did not return a dimension");
  }
}
export async function fetchDimensions(
  token: string | null,
): Promise<Dimension[]> {
  try {
    if (!token) {
      throw new Error("Auth token was not provided");
    }
    const response = await axios.get(`${backendURL}/dimension`, {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.data?.dimensions) {
      return response?.data?.dimensions;
    } else {
      throw new Error("did not return a dimension list");
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const message = error.response?.data.message;
      throw new Error(message);
    } else {
      throw new Error(error.message);
    }
  }
}
export async function fetchDimensionById(
  dimensionId: string,
  token: string | null,
): Promise<Dimension> {
  try {
    // if (!token) {
    //   throw new Error("Auth token was not provided");
    // }
    const response = await axios.get(`${backendURL}/dimension/${dimensionId}`, {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token ?? ""}`,
      },
    });
    if (response?.data?.dimension) {
      return response?.data?.dimension as Dimension;
    } else {
      throw new Error("did not return a dimension");
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const message = error.response?.data.message;
      throw new Error(message);
    } else {
      throw new Error(error.message);
    }
  }
}

export async function updateDimension(
  dimensionId: string,
  updateData: {
    title?: string;
    useTemplate: boolean;
    dimensionType: string;
    prototypeText: string;
    iterations?: number;
    description?: string;
    llmPrompt?: string;
    sampleText?: number;
    sampleScore?: number;
    characteristicIds?: string[];
    dimensionIds?: string[];
  },
  token: string | null,
): Promise<Dimension> {
  if (!token) {
    throw new Error("Auth token was not provided");
  }
  const response = await axios.put(
    `${backendURL}/dimension/${dimensionId}`,
    updateData,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.dimension) {
    return response.data.dimension;
  } else {
    throw new Error("Failed to update dimension");
  }
}

export async function deleteDimension(
  dimensionId: string,
  token: string,
): Promise<void> {
  if (!token) {
    throw new Error("Auth token was not provided");
  }
  const response = await axios.delete(
    `${backendURL}/dimension/${dimensionId}`,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response.status !== 200) {
    throw new Error("Failed to delete dimension");
  }
}
