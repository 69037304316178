import { ReactNode } from "react";
// import { ReactComponent as NotificationBell } from "./logos/notificationBell.svg";

import CaseStudyCard from "./CaseStudyCard";
import { Card, CardContent, CardTitle } from "../ui/card";
export interface PressCardProps {
  header: string;
  subHeader: string;
  imageLink: string;
  description: ReactNode[];
  caseStudy: ReactNode;
  caseStudyHeader: string;
}
const HowItWorksStep = (props: PressCardProps) => {
  // Close dropdown if clicked outside
  const {
    header,
    subHeader,
    imageLink,
    description,
    caseStudyHeader,
    caseStudy,
  } = props;

  return (
    <Card className="p-1 md:p-8">
      <CardTitle className="text-3xl md:text-4xl mb-4 font-bold text-center">
        {header}
      </CardTitle>
      <CardContent className="flex flex-col gap-6">
        <div className="grid lg:grid-cols-2 gap-28">
          <div className="hidden lg:block">
            <img src={imageLink}></img>
          </div>
          {/* this will be a news card */}
          <div className="flex flex-col gap-4 h-full items-start justify-center">
            <h4 className="header-henna-6 font-bold">{subHeader}</h4>
            <img src="./divider-start.png" alt="divider" />

            <ul className="list-disc text-lg">
              {description.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
        <CaseStudyCard
          header={caseStudyHeader}
          body={caseStudy}
        ></CaseStudyCard>
      </CardContent>
    </Card>
  );
};
// Figure out how to style the SignOutButton component later so we don't have to wrap it with a div for styling
export default HowItWorksStep;
