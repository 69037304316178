import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import exp from "constants";
import { useOutletContext, useParams } from "react-router-dom";
import { fetchExperimentById, fetchExperiments } from "src/api/experimentAPI";
import { ExperimentCard } from "src/components/experiments/ExperimentCard";
import { SetupDimensionsAndCharacteristicsForExperiment } from "src/components/experiments/SetupDimensionsAndCharacteristicsForExperiment";
import { PageContainer } from "src/components/layout/PageContainer";
import SideBarLayout, {
  SidebarType,
} from "src/components/layout/SidebarLayout";
import { Experiment } from "src/types/models/Experiment";

export default function DimensionExperimentPage() {
  const { experimentId } = useParams();
  const { experiment } = useOutletContext<{
    experiment: Experiment | undefined;
  }>();

  return (
    <PageContainer>
      {experiment && (
        <SetupDimensionsAndCharacteristicsForExperiment
          experiment={experiment}
        />
      )}
    </PageContainer>
  );
}
