import { Experiment } from "src/types/models/Experiment";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";
import { CardBody } from "react-bootstrap";
import { InputContainer } from "../forms/InputContainer";

type ExperimentCardProps = {
  experiment: Partial<Experiment>;
};

export const ShowExperiment: React.FC<ExperimentCardProps> = ({
  experiment,
}) => {
  console.log("experiment", experiment);
  return (
    <Card>
      <CardHeader className=" rounded-t-sm p-2 bg-henna-green-accent text-off-white">
        {experiment.title}
      </CardHeader>
      <CardContent className="flex flex-col gap-4">
        <span>{experiment.description}</span>
        <InputContainer>
          <span>LLM Prompt: </span>{" "}
          <span className="col-span-2">{experiment.llmPrompt}</span>
        </InputContainer>
        <InputContainer>
          <span>Iterations: </span>{" "}
          <span className="col-span-2">{experiment.iterations}</span>
        </InputContainer>
        <InputContainer>
          <span>Sample Text: </span>{" "}
          <span className="col-span-2">{experiment.sampleText}</span>
        </InputContainer>
        <InputContainer>
          <span>Sample Score: </span>{" "}
          <span className="col-span-2">
            {experiment.sampleScore} / {experiment.scoreDenominator}
          </span>
        </InputContainer>
        <InputContainer>
          <span>Submitted Text Text: </span>{" "}
          <span className="col-span-2">{experiment.prototypeText}</span>
        </InputContainer>
        <InputContainer>
          <span>Is this Experiment Public? </span>{" "}
          <span className="col-span-2">
            {experiment.isPublic ? "Yes" : "No"}
          </span>
        </InputContainer>
      </CardContent>
      <CardFooter>
        <InputContainer>
          <span>Experiment ID: </span>{" "}
          <span className="col-span-2">{experiment.id}</span>
        </InputContainer>
      </CardFooter>
    </Card>
  );
};
