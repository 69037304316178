import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MessagesPage from "./pages/chat/MessagesPage";
import NotFoundPage from "./pages/NotFoundPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AuthClerkPage from "./pages/AuthClerkPage";
import AdminPage from "./pages/chat/AdminPage";
import { ProtectedRouteIsLoggedIn } from "./components/protectedRotes/ProtectedRouteIsLoggedIn";
import ContextProvider from "./context/ContextProvider";
import TeamPage from "./pages/chat/TeamPage";
import { ProtectedRouteIsTeamLeader } from "./components/protectedRotes/ProtectedRouteIsTeamLeader";
import { ProtectedRouteIsAdmin } from "./components/protectedRotes/ProtectedRouteIsAdmin";
import JoinOrgAndTeamPage from "./pages/chat/JoinOrgAndTeamPage";
import AccountPage from "./pages/chat/AccountPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsPage from "./pages/TermsPage";
import HomePage from "./pages/HomePage";
import LeaderBoardPage from "./pages/LeaderboardPage";
import ChatDashboardPage from "./pages/chat/DashboardPage";
import CreateExperimentPage from "./pages/CreateExperimentPage";
import HowItWorksPage from "./pages/HowItWorksPage";
import ListExperimentsPage from "./pages/ListExperimentsPage";
import ShowExperimentPage from "./pages/ShowExperimentPage";
import SidebarLayout from "./components/layout/SidebarLayout";
import DimensionExperimentPage from "./pages/DimensionExperimentPage";
import { CreateEditExperimentForm } from "./components/experiments/CreateEditExperimentForm";
import EditExperimentPage from "./pages/EditExperimentPage";
import PersonaExperimentPage from "./pages/PersonaExperimentPage";
import { SetupLlmsForExperiment } from "./components/experiments/SetupLlmsForExperiment";
import RunExperimentPage from "./pages/RunExperimentPage";
import LlmExperimentPage from "./pages/LlmExperimentPage";
import { ExperimentResults } from "./components/experiments/ExperimentResults";
import ExperimentResultsPage from "./pages/ExperimentResultsPage";
import RootLayout from "./components/layout/RootLayout";
// import { ProctorDashboardPage } from "./pages/proctor/ProctorDashboardPage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      retryDelay: (attemptIndex) => Math.min(500 * 2 ** attemptIndex, 10000),
    },
  },
});
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<RootLayout />}>
              <Route index element={<HomePage />} />
              <Route path="/how-it-works" element={<HowItWorksPage />} />
              <Route path="/leaderboard" element={<LeaderBoardPage />} />
              <Route
                path="/experiment"
                element={
                  <ProtectedRouteIsLoggedIn>
                    <SidebarLayout />
                  </ProtectedRouteIsLoggedIn>
                }
              >
                <Route index element={<ListExperimentsPage />}></Route>
                <Route
                  path="/experiment/create"
                  element={<CreateExperimentPage />}
                />
                <Route
                  path="/experiment/:experimentId"
                  element={<ShowExperimentPage />}
                />
                <Route
                  path="/experiment/:experimentId/edit"
                  element={<EditExperimentPage />}
                />
                <Route
                  path="/experiment/:experimentId/dimension"
                  element={<DimensionExperimentPage />}
                />
                <Route
                  path="/experiment/:experimentId/persona"
                  element={<PersonaExperimentPage />}
                />
                <Route
                  path="/experiment/:experimentId/llm"
                  element={<LlmExperimentPage />}
                />{" "}
                <Route
                  path="/experiment/:experimentId/run"
                  element={<RunExperimentPage />}
                />
                <Route
                  path="/experiment/:experimentId/results"
                  element={<ExperimentResultsPage />}
                />
              </Route>

              {/* <Route
              path="/proctor/dashboard"
              element={<ProctorDashboardPage />}
            /> */}
              <Route
                path="/chat/dashboard"
                element={
                  <ProtectedRouteIsLoggedIn>
                    <ChatDashboardPage />
                  </ProtectedRouteIsLoggedIn>
                }
              />
              <Route path="/chat/home" element={<HomePage />} />
              <Route
                path="/chat/admin"
                element={
                  <ProtectedRouteIsAdmin>
                    <AdminPage />
                  </ProtectedRouteIsAdmin>
                }
              />
              <Route
                path="/chat/messages"
                element={
                  <ProtectedRouteIsLoggedIn>
                    <MessagesPage />
                  </ProtectedRouteIsLoggedIn>
                }
              />
              <Route
                path="/chat/team"
                element={
                  <ProtectedRouteIsTeamLeader>
                    <TeamPage />
                  </ProtectedRouteIsTeamLeader>
                }
              />
              {/* <Route path="/dashboard" element={<DashboardPage />} /> */}
              <Route path="/auth" element={<AuthClerkPage />} />
              <Route
                path="/chat/join"
                element={
                  <ProtectedRouteIsLoggedIn>
                    <JoinOrgAndTeamPage />
                  </ProtectedRouteIsLoggedIn>
                }
              />
              <Route
                path="/chat/account"
                element={
                  <ProtectedRouteIsLoggedIn>
                    <AccountPage />
                  </ProtectedRouteIsLoggedIn>
                }
              />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/terms" element={<TermsPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </Router>
      </ContextProvider>
    </QueryClientProvider>
  );
}

export default App;
