import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useRef, useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import { fetchConversationById } from "../../api/conversationAPI";
import { createMessage } from "../../api/messageAPI";
import MessageInputForm from "./MessageInputForm";
import MessageList from "./MessageList";
import { MyContext } from "../../context/Context";
import { Conversation } from "../../types/models/Conversation";
import CircularIndeterminate from "../CircularIndeterminate";

interface ChatWindowProps {
  conversationId: string;
}
const backendURL = process.env.REACT_APP_BACKEND_URL_WEB_SOCKETS;

const ChatWindow: React.FC<ChatWindowProps> = ({ conversationId }) => {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const messageRef = useRef<HTMLDivElement>(null);
  const [inputWidth, setInputWidth] = useState("60%");
  const { user } = useContext(MyContext);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const teamId = user?.selectedTeamId ?? "";
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [newMessage, setNewMessage] = useState("");
  const [latestResponse, setLatestResponse] = useState("");
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    // const { current } = messagesEndRef;
    // if (!current) return;

    // const scrollOffset = 100; // pixels from the bottom to be considered at the bottom
    // const isAtBottom =
    //   current.scrollHeight - current.scrollTop - current.clientHeight <
    //   scrollOffset;

    // if (isAtBottom) {
    //   current.scrollIntoView({ behavior: "smooth" });
    // }
  };

  useEffect(() => {
    const updateInputWidth = () => {
      if (messageRef.current) {
        const messageWidth = messageRef.current?.offsetWidth ?? "";
        setInputWidth(`${messageWidth}px`); // Update state, and thus the width
      }
    };

    updateInputWidth();
    const intervalId = setInterval(() => {
      if (messageRef.current) {
        updateInputWidth();
        clearInterval(intervalId); // Clear interval once the ref is assigned
      }
    }, 100); // Check every 100 milliseconds
    window.addEventListener("resize", updateInputWidth);
    return () => {
      clearInterval(intervalId);
      window.removeEventListener("resize", updateInputWidth);
    };
  }, [messageRef]);

  const { isPending, error, data, status } = useQuery({
    queryKey: ["conversation", conversationId, user?.selectedOrganizationId],
    queryFn: () =>
      (async () => {
        const freshToken = await getToken(); // Retrieve fresh token asynchronously
        if (!freshToken || !user?.selectedOrganizationId || !teamId) {
          throw new Error(
            "We could not complete this request, some information was missing",
          );
        }
        return fetchConversationById(
          user.selectedOrganizationId,
          teamId,
          conversationId,
          freshToken,
        );
      })(),
    enabled: !!teamId && !!teamId,
  });

  useEffect(() => {
    let ws: WebSocket;
    const establishConnection = async () => {
      const token = await getToken();
      ws = new WebSocket(
        `${window.location.protocol === "https:" ? "wss://" : "ws://"}${backendURL}?token=${token}`,
      );
      ws.onopen = () => {
        // console.log("WebSocket connection established");
        console.log("type:", ws.url.startsWith("wss://") ? "wss" : "ws");
      };
      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === "pong") {
          return;
        }
        const message = data.message ?? "";
        const currentData = queryClient.getQueryData([
          "conversation",
          conversationId,
          user?.selectedOrganizationId,
        ]) as Conversation;
        let updatedData = {};
        if (currentData && currentData?.messages && message) {
          const lastMessageIndex = currentData.messages.length - 1;
          const lastMessage = currentData.messages[lastMessageIndex];
          if (lastMessage.isStream === true) {
            const updatedLastMessage = {
              ...lastMessage,
              text: lastMessage.text + message,
            };
            const updatedMessages = [
              ...currentData.messages.slice(0, lastMessageIndex),
              updatedLastMessage,
            ];
            // Create a new array of messages with the updated last message
            updatedData = { ...currentData, messages: updatedMessages };
          } else {
            updatedData = {
              ...currentData,
              messages: [
                ...currentData.messages,
                { text: message, isStream: true },
              ],
            };
          }
          queryClient.setQueryData(
            ["conversation", conversationId, user?.selectedOrganizationId],
            updatedData,
          );
        }
        scrollToBottom();
      };
      ws.onclose = () => {
        // console.log(
        //   `WebSocket closed with reason: ${event.reason}. Attempting to reconnect...`,
        // );
        setTimeout(establishConnection, 2500); // Try to reconnect every 2.5 seconds
      };
      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        ws.close(); // Ensure the connection is cleaned up properly
      };
    };

    establishConnection().catch((error) => console.log(error));

    return () => {
      if (ws) {
        ws.close(); // Clean up WebSocket connection when component unmounts
      }
    };
  }, []);

  useEffect(() => {
    if (status === "success") {
      scrollToBottom();
    }
  }, [status, data]); // Depend on `status` and `data` to run after changes

  const createMessageMutation = useMutation({
    mutationFn: () =>
      (async () => {
        const freshToken = await getToken();
        if (
          !freshToken ||
          !user?.selectedOrganizationId ||
          !conversationId ||
          !newMessage
        ) {
          throw new Error("Your message could not be sent");
        }
        const sendingMessage = newMessage;
        setNewMessage("");
        return createMessage(
          user.selectedOrganizationId,
          teamId as string,
          conversationId,
          sendingMessage,
          freshToken,
        );
      })(),
    onSuccess: () => {
      setSuccessMessage("Message creation successful!");
      setTimeout(() => setSuccessMessage(""), 3000); // Clear the success message after 3 seconds
      queryClient.invalidateQueries({
        queryKey: ["conversation"],
      });
      setLatestResponse("");
    },
    onError: (error: Error) => {
      console.error("Message creation error", error);
      setTimeout(() => setErrorMessage(""), 6000); // Clear the error message after 6 seconds
    },
  });

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (data) {
      const optimisticUpdate = [
        ...data.messages,
        { text: newMessage, user: { email: "test" } },
      ]; // Assuming `data.messages` exists and is the array of messages
      queryClient.setQueryData(
        ["conversation", conversationId, user?.selectedOrganizationId],
        {
          ...data,
          messages: optimisticUpdate,
        },
      );
    }

    createMessageMutation.mutate();
  };
  console.log("conversationId", conversationId, data);
  return (
    <div className="relative size-full flex flex-col items-center min-h-screen flex-grow">
      <div className="chat-background h-full w-full flex-grow"></div>
      <div className="chat-content h-full w-full flex flex-col justify-end items-center ">
        <div className="w-full overflow-auto px-3 pt-6 flex-grow">
          {isPending && <CircularIndeterminate />}
          {error && (
            <p className="text-red-500">
              An error has occurred: {error.message}
            </p>
          )}
          <MessageList
            data={data}
            latestResponse={latestResponse}
            messageRef={messageRef}
          />
          {createMessageMutation.isPending && <span>...</span>}
          {createMessageMutation.isError && (
            <span>There was an error sending or receiving this message.</span>
          )}
          {/* spacer element */}
          <div className="w-full h-24"></div>
          <div
            className="h-1"
            ref={messagesEndRef}
            data-name={"end-ref"}
          />{" "}
          {/* Invisible element to scroll to */}
        </div>
        <MessageInputForm
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          handleSubmit={handleFormSubmit}
          errorMessage={errorMessage}
          successMessage={successMessage}
          isPending={createMessageMutation.isPending}
          inputWidth={inputWidth}
        />
      </div>
    </div>
  );
};

export default ChatWindow;
