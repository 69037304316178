const routes = {
  home: "/",
  howItWorks: "/how-it-works",
  leaderboard: "/leaderboard",
  experiment: {
    base: "/experiment",
    create: "/experiment/create",
    show: (experimentId: string) => `/experiment/${experimentId}`,

    editExperimentBasics: (experimentId: string) =>
      `/experiment/${experimentId}/edit`,
    dimensionsAndCharacteristicsForExperiment: (experimentId: string) =>
      `/experiment/${experimentId}/dimension`,
    personasForExperiment: (experimentId: string) =>
      `/experiment/${experimentId}/persona`,
    llmsForExperiment: (experimentId: string) =>
      `/experiment/${experimentId}/llm`,
    runExperiment: (experimentId: string) => `/experiment/${experimentId}/run`,
    experimentResults: (experimentId: string) =>
      `/experiment/${experimentId}/results`,
  },
  chat: {
    dashboard: "/chat/dashboard",
    home: "/chat/home",
    admin: "/chat/admin",
    messages: "/chat/messages",
    team: "/chat/team",
    join: "/chat/join",
    account: "/chat/account",
  },
  privacyPolicy: "/privacy-policy",
  terms: "/terms",

  notFound: "*",
};

export default routes;
